// import { currentUser } from "@/firebase/firebase-auth";
import { defineStore } from "pinia";

import * as Sentry from "@sentry/vue";

//Use the import let = require syntax on TS.
import { SplitFactory } from "@splitsoftware/splitio";
import SplitIO from "@splitsoftware/splitio/types/splitio";
import { useUserStore } from "./useUserStore";


// Instantiate the SDK
const factory: SplitIO.ISDK = SplitFactory({
  core: {
    authorizationKey: "iqs3a60l6i3j15hiapfgdb1qnpofsambbsm1",
    // the key can be the logged in
    // user id, or the account id that
    // the logged in user belongs to.
    // The type of customer (user, account, custom)
    // is chosen during Split's sign-up process.
    key: "dummy",

    // leave the user key as a dummy for now//
  },
  startup: {
    readyTimeout: 1.5, // 1.5 sec
  },
});

/** Make and initialise a client for the user
 * @returns the client
 */
const makeClientPromise = (key: string): Promise<SplitIO.IClient> => {
  return new Promise((resolve, reject) => {
    try {
      const client = factory.client(key);
      client.on(client.Event.SDK_READY, function () {
        resolve(client);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const useFeatureFlagsStore = defineStore("filter-flags", {
  /** state factory settings */
  state: () => ({
    userKey: "", // which user was the client initialised for, if any. Normally the uid from currentUser
    client: null, // the singleton client for this user
    initialised: false,
    initialising: false,

    // feature flags

    unfilteredSearchFlag: false,
    freeTrial7Days: false,
    autoUpdate: false,
    purgeCompletedTasksFlag: false,
    sortByContextFlag: false,
    stripeFreeTrialFlag: true, // constant value as we don't have a currentUser when we look at this flag
    newRegisterPageFlag: false, // constant value but false as it isn't ready yet
    newLoginPageFlag: false, // constant value but false as it isn't ready yet
    // newEntitlementChecksFlag: false, // set in init below
    stripeDirectFlag: false,
    firebaseStripeExtensionFlag: false,
    // debugEntitlementsFlag: false,
    zapierConnectFeatureFlag: false,
    sortUndatedAtEndFlag: true,
    // noEntitlementChecks: false,
    importFeatureFlag: false,
    exportFeatureFlag: false,
    addedDateFilterFlag: false,
    selectTasksFlag: false,
    testNewSignupFlag: false,
    filterLinkFlag: false,
    newHamburgerMenuFlag: true,
    manageSubscriptionFlag: true,
    newFolderListFlag: false,
    bulkDeleteFeatureFlag: false,
    installPWAFlag: false,
    freePassFlag: false,
    selectTasksFeatureFlag: false,
    adoptSubtasksFeatureFlag: false,
    promoteSubtaskFeatureFlag: false,
    expandingParentFeatureFlag: false,
  }),
  // persist: true,

  getters: {
    // getFeatureFlagForUser: () => (flag: string, key:string): boolean => {
    //   init()
    //   return false;
    // }
  }, // getters

  actions: {
    /** Initialise the Feature Flag store
     *
     * @remarks This calls the client factory, which has a callback, so we are writing it as a Promise.
     * @returns true when initialisation succeeds, and false if it is skipped
     */
    async init(): Promise<boolean> {
      // console.log(`FeatureFlagsStore.init has started`)
      const userStore = useUserStore();
      if (this.initialising) {
        // console.log(`useFeatureFlagsStore init is already initialising, so does nothing`);
        return Promise.resolve(false);
      }

      // const userStore = useUserStore();
      // const { user } = storeToRefs(userStore);
      if (!userStore.user?.uid) {
        // console.log(`useFeatureFlagsStore init sees no current user uid, so does nothing`);
        return Promise.resolve(false);
      }
      this.initialising = true;
      // console.log(`useFeatureFlagsStore init is creating created a context for currentUser: ${JSON.stringify(currentUser)}`);
      const userKey = userStore.user.uid;
      this.userKey = userKey;
      // console.log(`User key for ${currentUser.email} is ${userKey}`)

      // console.log(`useFeatureFlagsStore init is has created a user key: ${JSON.stringify(this.userKey)}`);
      try {
        this.client = await makeClientPromise(userKey);
        // console.log(`useFeatureFlagsStore init sees makeClientPromise has returned`);

        this.initialised = true;
        this.initialising = false;

        // as we have awaited makeClientPromise, the feature flags should be available now

        const treatment: SplitIO.Treatment = this.client.getTreatment(
          "feature-unfiltered-search-results"
        );
        // console.log(`useFeatureFlagsStore init sees treatment ${treatment}`);
        this.unfilteredSearchFlag = treatment == "on";
        // console.log(`useFeatureFlagsStore init on sdk ready has set unFilteredSearchFlag to: ${this.unfilteredSearchFlag}`);

        const freeTrial7DaysTreatment: SplitIO.Treatment =
          this.client.getTreatment("free-trial-7-days");
        this.freeTrial7Days = freeTrial7DaysTreatment == "on";
        this.autoUpdate = this.client.getTreatment("auto-update") == "on";
        this.purgeCompletedTasksFlag =
          this.client.getTreatment("Purge_Completed") == "on";
        this.sortByContextFlag =
          this.client.getTreatment("sort-by-context") == "on";
        this.stripeFreeTrialFlag =
          this.client.getTreatment("stripe-free-trial") == "on";
        // this.newEntitlementChecksFlag =
        //   this.client.getTreatment("new-entitlement-checks") == "on";
        this.stripeDirectFlag =
          this.client.getTreatment("stripe-direct") == "on";
        this.firebaseStripeExtensionFlag =
          this.client.getTreatment("firebase-stripe-extension") == "on";
        // this.debugEntitlementsFlag =
        //   this.client.getTreatment("debug-entitlements") == "on";
        this.zapierConnectFeatureFlag =
          this.client.getTreatment("zapier-connect") == "on";
        // this.noEntitlementChecks =
        //   this.client.getTreatment("no-entitlement-checks") == "on";
        this.importFeatureFlag = this.client.getTreatment("import") == "on";
        this.exportFeatureFlag = this.client.getTreatment("export") == "on";
        this.addedDateFilterFlag =
          this.client.getTreatment("added-date-filter") == "on";
        this.selectTasksFlag = this.client.getTreatment("select-tasks") == "on";
        this.testNewSignupFlag =
          this.client.getTreatment("test-new-registration") == "on";
        this.filterLinkFlag = this.client.getTreatment("filter-link") == "on";
        // this.newHamburgerMenuFlag =
        //   this.client.getTreatment("new-hamburger-menu") == "on";
        this.manageSubscriptionFlag =
          this.client.getTreatment("manage-subscription") == "on";
        this.newFolderListFlag =
          this.client.getTreatment("new-folder-list") == "on";
        this.bulkDeleteFeatureFlag =
          this.client.getTreatment("bulk_delete") == "on";
        this.installPWAFlag =
          this.client.getTreatment("install-pwa") == "on";
        this.freePassFlag =
          this.client.getTreatment("free-pass") == "on";
        this.selectTasksFeatureFlag = this.client.getTreatment("select-tasks") == "on";
        this.adoptSubtasksFeatureFlag = this.client.getTreatment("adopt-subtasks") == "on";
        this.promoteSubtaskFeatureFlag = this.client.getTreatment("promote-subtask") == "on";
        this.expandingParentFeatureFlag = this.client.getTreatment("expanding-parent") == "on";

        // console.log(`FeatureFlags store has new-hamburger-menu flag as ${this.newHamburgerMenuFlag}`);

        // console.log(`Finished loading feature flags`);
        this.initialising = false;
        this.initialised = true;
        return true;
      } catch (err: any) {
        // console.log(
        //   `Error when accessing feature flags online.`,
        //   err
        // );

        Sentry.captureException(err, {
          tags: {
            section: "FeatureFlags",
          },
        });

        this.initialising = false;
        this.initialised = false;
        // console.log(`FeatureFlags store has new-hamburger-menu flag as ${this.newHamburgerMenuFlag}`);
      }
    },
  },
}); // define store
