import { DateTime } from "luxon";
import { TaskObject } from "../TaskObject";
import { DateFilterOption } from "../filters/DateFilterOption";
import { Filter } from "./Filter";

/**
 * Filters tasks by start date, using friendly terms like 'overstart', 'today' and 'undated'
 */
export class ImportedDateFilter extends Filter {
    public get debugDescription(): string {
        return `Imported date filter: ${this.value}`;
    }
    value: DateFilterOption;
    constructor(value: DateFilterOption) {
        // console.log(`Constructing imported date filter for date option ${value}`)
        super(value);
        this.value = value;
    }

    /**
     * Returns true if a task matches the value expressed as Today, Yesterday or whatever
     * @param item - the task
     *
     * @remarks
     * The value for comparison is set in the constructor
     */
    public isFiltered(item: TaskObject): boolean {
        let result = false;
        const now = DateTime.local();
        const today = now.startOf("day").toUTC(); // imported date is a utc DateTime so we need to allow for that here
        const imported = item.imported;

        if (!imported) {
            // undated
            // console.log(`Imported date filter for date option ${this.value} gives result ${this.value === DateFilterOption.Undated} for task with no imported date`)
            return this.value === DateFilterOption.Undated;
        }

        switch (this.value) {
            case DateFilterOption.Today:
                result = !!imported && imported > today && imported < today.plus({ days: 1 });
                break;
            case DateFilterOption.Yesterday:
                result = !!imported && imported > today.minus({ days: 1 }) && imported < today;
                break;
            case DateFilterOption.Week:
                result =
                    !!imported &&
                    imported < today.plus({ days: 1 }) &&
                    imported > today.minus({ days: 7 });
                break;
            case DateFilterOption.Month:
                result =
                    !!imported &&
                    imported < today.plus({ days: 1 }) &&
                    imported > today.minus({ months: 1 });
                break;
            case DateFilterOption.Quarter:
                result =
                    !!imported &&
                    imported < today.plus({ days: 1 }) &&
                    imported > today.minus({ months: 3 });
                break;
            case DateFilterOption.Year:
                result =
                    !!imported &&
                    imported < today.plus({ days: 1 }) &&
                    imported > today.minus({ years: 1 });
                break;
            case DateFilterOption.Older:
                result = !!imported && imported < today.minus({ years: 1 });
                break;

        }

        // console.log(`Imported date filter for date option ${this.value} gives result ${result} for task ${item.title} with imported date ${imported}`)
        return result;
    }

    /**
     * Calculates a description from the comparison value
     *
     * @remarks
     * Quarter is translated to '3 months'
     */
    public get description() {
        switch (this.value) {
            case DateFilterOption.Undated:
                return "not imported";
            case DateFilterOption.Today:
                return "imported today";
            case DateFilterOption.Yesterday:
                return "imported yesterday";
            case DateFilterOption.Week:
                return "imported in past week";
            case DateFilterOption.Month:
                return "imported in past month";
            case DateFilterOption.Quarter:
                return "imported in past 3 months";
            case DateFilterOption.Year:
                return "imported in past year";
            case DateFilterOption.Older:
                return "imported more than a year ago";
            default:
                return undefined;
        }
    }
}
