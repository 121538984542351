import { Constants } from "@/constants/constants"
import { TaskObject } from "@/models/TaskObject"
import { DateTime, Duration, Interval } from "luxon"

export class RepeatHelper {

/** Get a parameter from an iCal repeat string
 * 
 * @param iCal The repeat string in iCal format
 * @param key The key of the parameter to be inspected
 * @returns The value of the parameter as a string, or an empty string if the value is not present
 */
 static getRepeatParameter = (iCal: string, key: string): string => {
    let result = ''
    const index = iCal.indexOf(key)
    if (index < 0) {
      return ''
    }
    const remainderIndex = index + key.length + 1 // add one to step past the = sign
    const terminatorIndex = iCal.indexOf(';', remainderIndex)
    if (terminatorIndex < 0) {
      // no terminator so return the entire remainder
      result = iCal.substring(remainderIndex)
      return result.trim()
    }
    // we have a terminator
    result = iCal.substring(remainderIndex, terminatorIndex)
    return result.trim()
  }
  
  /** Calculate a string we can display to the user, from an iCal repeat string
   * 
   */
  static calcRepeatDisplayStringFor = (task: TaskObject): string => {
    if (task.repeat == null) {
      return "Never"
    }
  
    const repeat = task.repeat
  
    const intervalString = RepeatHelper.getRepeatParameter(repeat, "INTERVAL")
    const interval = intervalString.length == 0 ? 0 : parseInt(intervalString, 10)
  
    if (repeat.includes("PARENT")) {
      return "With Parent"
    }
    if (repeat.includes("COMP")) {
      if (repeat.includes("DAILY")) {
        if (interval === 0) {
          return "Tomorrow"
        }
        return `Next ${interval} days`
      }
      if (repeat.includes("WEEKLY")) {
        if (interval === 0) {
          return "Next week"
        }
        return `Next ${interval} weeks`
      }
      if (repeat.includes("MONTHLY")) {
        if (interval === 0) {
          return "Next month"
        }
        return `Next ${interval} months`
      }
      if (repeat.includes("YEARLY")) {
        if (interval === 0) {
          return "Next year"
        }
        return `Next ${interval} years`
      }
  
  
      if (repeat.includes("BYDAY")) {
        const daysString = RepeatHelper.getRepeatParameter(repeat, "BYDAY")
        // console.log(`calcRepeatDisplayString sees daysString = ${daysString}`)
        if (daysString.length > 0) {
          switch (daysString) {
            case "MO,TU,WE,TH,FRI":
  
              // console.log(`calcRepeatDisplayString returns "Next weekday"`)
              return "Next weekday"
            case "SU,SA":
              // console.log(`calcRepeatDisplayString returns "Next weekend day"`)
              return "Next weekend day"
            default:
              // console.log(`calcRepeatDisplayString returns "Never"`)
              return "Never"
          }
        }
      }
  
      // at this point, we have exhausted the iCal format.
      // Perhaps it's not in iCal fomat, so just return it.
  
      return repeat
  
    } // from completed date
  
    // not from completed date
  
    if (repeat.includes("DAILY")) {
      if (interval === 0) {
        return "Every day"
      }
      return `Every ${interval} days`
    }
    if (repeat.includes("WEEKLY")) {
      if (interval === 0) {
        return "Weekly"
      }
      if (interval === 2) {
        return "Biweekly"
      }
      return `Every ${interval} weeks`
    }
    if (repeat.includes("MONTHLY")) {
      if (interval === 0) {
        return "Every month"
      }
      if (interval === 3) {
        return "Quarterly"
      }
      if (interval === 6) {
        return "Semiannually"
      }
      return `Every ${interval} months`
    }
    if (repeat.includes("YEARLY")) {
      if (interval === 0) {
        return "Yearly"
      }
      return `Every ${interval} years`
    }
  
    if (repeat.includes("BYDAY")) {
      const daysString = RepeatHelper.getRepeatParameter(repeat, "BYDAY")
      // console.log(`calcRepeatDisplayString sees daysString = ###${daysString}###`)
      if (daysString.length > 0) {
        switch (daysString) {
          case "MO,TU,WE,TH,FR":
            // console.log(`calcRepeateDisplayString returns "Weekdays"`)
            return "Weekdays"
          case "SU,SA":
            // console.log(`calcRepeateDisplayString returns "Weekends"`)
            return "Weekends"
          default:
            // console.log(`calcRepeateDisplayString returns "Never"`)
            return "Never"
        }
      }
    }
    // at this point, we have exhausted the iCal format.
    // Perhaps it's not in iCal fomat, so just return it.
  
    return repeat
  }
  
  /**
   * Calculates a new date, given a repeat string
   * @param repeat A repeat string as an iCal string or a simple choice like "Daily"
   * @param fromDate The date to calculate from
   */
 static calcRepeatingDate = (
    repeat: string,
    fromDate: DateTime
  ): DateTime | undefined => {
    if (repeat == null || repeat === Constants.REPEAT_NONE) {
      return undefined;
    }

    let result:DateTime;

    const today = DateTime.local().startOf('day')

    let baseDate: DateTime;
    let baseTimeOfDay: Duration;

   if (fromDate){
     baseDate = fromDate.startOf('day')
     baseTimeOfDay = fromDate.diff(baseDate)
   } else {
      baseDate = DateTime.local().startOf('day')
      baseTimeOfDay = Duration.fromMillis(0)
   }
 
  
  
    // baseDate comes from the due date.
  
    // if the baseDate is long in the past, we want to change it so that the calculated repeated date is in the future
    // this will be the baseDate plus as many repeat intervals as we need to get into future
  
    // work out time since base date
    // work out how many repeat intervals should have elapsed
    // apply that number of repeat intervals.
  
    // console.log(`calcRepeatingDate starting with repeatString: ${repeat} fromDate: ${fromDate}`)
  
  
    // first try the iCal format
  
    const intervalString = RepeatHelper.getRepeatParameter(repeat, "INTERVAL")
    const interval = intervalString.length == 0 ? 0 : parseInt(intervalString, 10)
   
    const isFuture = baseDate > today; 
    let daysSinceBaseDate = -1
    let weeksSinceBaseDate = -1
    let biWeeksSinceBaseDate = -1
    let monthsSinceBaseDate = -1
    let yearsSinceBaseDate = -1
    let quartersSinceBaseDate = -1
  
    if (!isFuture) {
      // console.log(`base date is not in the future`)
      daysSinceBaseDate = Math.floor(Interval.fromDateTimes(baseDate, today).length('days'))
      weeksSinceBaseDate = Math.floor(daysSinceBaseDate / 7)
      biWeeksSinceBaseDate = Math.floor(daysSinceBaseDate / 14)
      monthsSinceBaseDate = Math.floor(Interval.fromDateTimes(baseDate, today).length('months'))
      quartersSinceBaseDate = Math.floor(monthsSinceBaseDate / 3)
      yearsSinceBaseDate = Math.floor(Interval.fromDateTimes(baseDate, today).length('years'))

    } 
    
    // else {
    //   console.log(`base date is in the future`)
    // }
  
    // if (repeat.includes("COMP")) {
    if (repeat.includes("DAILY")) {
      if (interval < 2) {
        const days = isFuture ? 1 : daysSinceBaseDate + 1
        result = baseDate.plus({ days }).plus(baseTimeOfDay);
        // console.log(`calcRepeatingDate returns ${result}`)
        return result
      }
      const days = interval + (isFuture ? 1 : daysSinceBaseDate)
      result = baseDate.plus({ days }).plus(baseTimeOfDay);
      // console.log(`calcRepeatingDate returns ${result}`)
      return result
  
    }
    if (repeat.includes("WEEKLY")) {
  
      if (interval < 2) {
        const periods = isFuture ? 1 : weeksSinceBaseDate + 1
        const days = periods * 7
        result = baseDate.plus({ days }).plus(baseTimeOfDay);
        // console.log(`calcRepeatingDate returns ${result}`)
        return result
      }
  
      const periods = isFuture ? interval : weeksSinceBaseDate + interval
      const days = periods * 7
      result = baseDate.plus({ days }).plus(baseTimeOfDay);
      // console.log(`calcRepeatingDate returns ${result}`)
      return result
  
    }
    if (repeat.includes("MONTHLY")) {
      if (interval < 2) {
        const periods = isFuture ? 1 : monthsSinceBaseDate + 1
        result = baseDate.plus({ months: periods }).plus(baseTimeOfDay);
        // console.log(`calcRepeatingDate returns ${result}`)
        return result
      }
      const periods = isFuture ? interval : monthsSinceBaseDate + interval
      result = baseDate.plus({ months: periods }).plus(baseTimeOfDay);
      // console.log(`calcRepeatingDate returns ${result}`)
      return result
    }
    if (repeat.includes("YEARLY")) {
      if (interval < 2) {
        const periods = isFuture ? 1 : yearsSinceBaseDate + 1
        result = baseDate.plus({ years: periods }).plus(baseTimeOfDay);
        // console.log(`calcRepeatingDate returns ${result}`)
        return result
      }
      const periods = isFuture ? interval : yearsSinceBaseDate + interval
      result = baseDate.plus({ years: periods }).plus(baseTimeOfDay);
      // console.log(`calcRepeatingDate returns ${result}`)
      return result
    }
  
  
  
    const daysString = RepeatHelper.getRepeatParameter(repeat, "BYDAY")
  
    if (daysString.length > 0) {
      switch (daysString) {
        case "MO,TU,WE,TH,FR":
          // what day of the week is the base date?
          switch (today.weekday) {
            case 6: result = today.plus({ days: 2 }); // sat to mon
              break
            case 5: result = today.plus({ days: 3 }); // fri to mon
              break
            default:
              result = today.plus({ days: 1 }); // next day is a weekday
          }
          // console.log(`calcRepeatingDate returns ${result}`)
          return result.plus(baseTimeOfDay)
  
        case "SU,SA":
          // what day of the week is the base date?
          switch (today.weekday) {
            case 7: result = today.plus({ days: 6 }); // sun to sat
              break
            case 6: result = today.plus({ days: 1 }); // sat to sun
              break
            case 5: result = today.plus({ days: 1 }); // fri to sat
              break
            default:
              result = baseDate.plus({ days: 6 - baseDate.weekday });
          }
          break
        default:
          result = baseDate.plus(baseTimeOfDay) // do nothing
      }
    }
  
  
  
  
    // at this point, we have exhausted the iCal format.
    // Perhaps it's not in iCal fomat
  
  
    switch (repeat) {
      case Constants.REPEAT_DAILY:
        result = baseDate.plus({ days: isFuture ? 1 : daysSinceBaseDate + 1 })
        break
      case Constants.REPEAT_WEEKDAYS:
        switch (
        baseDate.weekday // Get the day of the week. 1 is Monday and 7 is Sunday
        ) {
          case 5: // friday
            result = baseDate.plus({ days: 3 }).plus(baseTimeOfDay);
            // console.log(`calcRepeatingDate returns ${result}`)
            return result
          case 6: // saturday
            result = baseDate.plus({ days: 2 }).plus(baseTimeOfDay);
            // console.log(`calcRepeatingDate returns ${result}`)
            return result
          default:
            result = baseDate.plus({ days: 1 }).plus(baseTimeOfDay);
            // console.log(`calcRepeatingDate returns ${result}`)
            return result
        }
      case Constants.REPEAT_WEEKLY:
        result = baseDate.plus({ days: isFuture ? 7 : weeksSinceBaseDate * 7 + 7 });
        // console.log(`calcRepeatingDate returns ${result}`)
        return result.plus(baseTimeOfDay)
      case Constants.REPEAT_BIWEEKLY:
        result = baseDate.plus({ days: isFuture ? 14 : biWeeksSinceBaseDate * 14 + 14 });
        // console.log(`calcRepeatingDate returns ${result}`)
        return result.plus(baseTimeOfDay)
      case Constants.REPEAT_MONTHLY:
        result = baseDate.plus({ months: isFuture ? 1 : monthsSinceBaseDate + 1 });
        // console.log(`calcRepeatingDate returns ${result}`)
        return result.plus(baseTimeOfDay)
      case Constants.REPEAT_QUARTERLY:
        result = baseDate.plus({ months: isFuture ? 3 : quartersSinceBaseDate * 3 + 3 });
        // console.log(`calcRepeatingDate returns ${result}`)
        return result.plus(baseTimeOfDay)
      case Constants.REPEAT_SEMIANNUALLY:
        result = baseDate.plus({ months: (isFuture ? 1 : monthsSinceBaseDate / 6) * 6 + 6 });
        // console.log(`calcRepeatingDate returns ${result}`)
        return result.plus(baseTimeOfDay)
      case Constants.REPEAT_YEARLY:
        result = baseDate.plus({ years: isFuture ? 1 : yearsSinceBaseDate + 1 });
        // console.log(`calcRepeatingDate returns ${result}`)
        return result.plus(baseTimeOfDay)
    }
    // console.log(`calcRepeatingDate returns undefined`)
    return undefined;
  };
}