import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import { createPinia } from 'pinia';


import { IonicVue } from "@ionic/vue";


/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */

import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "../src/style/normalize.css"; // my copy to fix hr etc

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/display.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";

import './assets/css/global.css'; // Import my global CSS file


// launch darkly
// import { LDPlugin, useLDClient } from 'launchdarkly-vue-client-sdk'
// import { ldInit } from "launchdarkly-vue-client-sdk";

import useFirebaseAuth from "./firebase/firebase-auth";

const { authCheck } = useFirebaseAuth();

import "./registerServiceWorker";

// install v-calendar and v-date-picker
import VCalendar from "v-calendar";

// vue-virtual-scroller

import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { Logger } from "./helpers/Logger";
// import { Quasar } from 'quasar'
// import quasarUserOptions from './quasar-user-options'

// Font Awesome icons
// font awesome imports
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(faUserSecret);

const pinia = createPinia()
// pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(VueVirtualScroller)
  .use(VCalendar, {
    componentPrefix: "vc",
  })
// .component("font-awesome-icon", FontAwesomeIcon)
// .use(LDPlugin, {clientSideID: clientSideID, deferInitialization: true})
// .use(LDPlugin, { clientSideID });


// console.log(`using LDPlugins in main.ts`)


Sentry.init({
  release: `taskangel-online ${process.env.VUE_APP_VERSION || "0"}`,
  app,
  dsn: "https://8fc4298c063a44f8a65152d198aee684@o344095.ingest.sentry.io/5699281",

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 1.0,
  // replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
  integrations: [

    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "app.taskangel.com", "https://taskangel-f1161--release-1-12-wrxieah8.web.app", /^\//],
    }),
  ],
  // look at error to see if is worth reporting

  ignoreErrors: [
    'TypeError: Load failed',
  ],

  // reduce the number of errors reported
  sampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


Logger.log(`calling authCheck in main.ts`)
authCheck()
  .then(() => {
    Logger.log(`running authCheck in main.ts`)
    // const user = { email: currentUser.email, }
    // app.use(LDPlugin, {clientSideID: clientSideID, user})
    app.use(router);
    return router.isReady();
  })
  .then(() => {
    app.mount("#app");
  });
