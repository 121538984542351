import {Constants} from "@/constants/constants";
import {checkoutSessionsCollectionRef, subscriptionsCollectionRef} from "@/firebase/firebase-auth";
import {Logger} from "@/helpers/Logger";
import {SubscriptionObject} from "@/models/SubscriptionObject";
import * as Sentry from "@sentry/vue";
import {loadStripe} from "@stripe/stripe-js";
import {doc, DocumentData, onSnapshot, QuerySnapshot, setDoc} from 'firebase/firestore';
import {DateTime} from "luxon";
import {defineStore, storeToRefs} from "pinia";
import {v4 as generateUUID} from 'uuid';
import {useFeatureFlagsStore} from "./useFeatureFlagsStore";
import {useUserStore} from "./useUserStore";

export enum JourneyStage {
  Unregistered = 0,
  Registered,
  InFreeTrial,
  FreeTrialExpired,
  Premium,
  Founder,
}

let firebaseSubscriptionsUnsubscribe: { (): void; (): void } | null = null;
export const useSubscriptionsStore = defineStore("subscriptionsStore", {
  state: () => ({
    isSubscribing: false,
    haveSubscriptionsLoaded: false,
    subscription: new SubscriptionObject(),
    subscriptionStatus: "",
    firebaseUser: null,
    stripe: null,
  }),

  getters: {

    freeTrialExpiryDate: (): DateTime | null => {
      const userStore = useUserStore();
      const featureFlagsStore = useFeatureFlagsStore();
      const { freeTrial7Days } = storeToRefs(featureFlagsStore);
      if (!userStore.user?.metadata) return null;
      const created = userStore.user.metadata.creationTime; // creation date as an utc string
      const createdDate = DateTime.fromHTTP(created);
      const last30Days = DateTime.utc(2023, 5, 23); // on 23/5/30 we reduced the free trial period from 30 days to 7
      if (createdDate < last30Days) {
        return createdDate.plus({ days: 30 });
      }
      return createdDate.plus({ days: freeTrial7Days.value ? 7 : 30 });
    },

    journeyStage: (state): JourneyStage.Registered | JourneyStage.InFreeTrial | JourneyStage.FreeTrialExpired | JourneyStage.Premium | JourneyStage.Founder | JourneyStage.Unregistered => {
      try {

        const userStore = useUserStore();
        if (!userStore.user?.metadata) {
          return JourneyStage.Unregistered;
        }

        const created = userStore.user.metadata.creationTime;
        if (created) {
          const createdDate = DateTime.fromHTTP(created);
          const lastFounder = DateTime.utc(2021, 4, 1);
          if (createdDate < lastFounder) {
            return JourneyStage.Founder;
          }
        }

        if (state.subscription) {
          return JourneyStage.Premium
        }

        if (state.subscription?.status === "active") {
          return JourneyStage.Premium;
        }

        if (created) {
          const userStore = useUserStore();
          const { user } = userStore;
          if (!user || !user.metadata) return null;

          const createdDate = DateTime.fromHTTP(created);

          const freeTrialExpiry = createdDate.plus({ days: 7 });
          if (freeTrialExpiry > DateTime.local()) {
            return JourneyStage.InFreeTrial;
          }
          return JourneyStage.FreeTrialExpired;
        }

        return JourneyStage.Unregistered
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    journeyStageAsString(): string {
      try {
        if (!this.journeyStage) return 'null';
        return JourneyStage[this.journeyStage];

      } catch (error) {
        Sentry.captureException(error)
      }
    },

    canSubscribe() {
      const userStore = useUserStore();
      if (!userStore.user?.metadata) {
        return true;
      }
      const userCreated = userStore.user.metadata?.creationTime; // creation date as an utc string

      if (!userCreated) {
        return true;
      }

      if (userStore.isFounder) return false;

      if (!this.subscription) return true;

      return this.subscription?.status !== "active";


    },

    canManage() {

      const userStore = useUserStore();
      const meta = userStore.user?.metadata
      if (!meta) {
        return false;
      }
      const userCreated = meta.creationTime;
      if (!userCreated) return false;

      if (userStore.isFounder) return false;
      if (!this.subscription) return false;
      return this.subscription?.status === "active";


    },


    statusMessage() {

      const userStore = useUserStore();

      if (!userStore.user && !this.firebaseUser) {
        return "Please sign to see your subscription status";
      }
      const featureFlagsStore = useFeatureFlagsStore();
      const { freePassFlag } = storeToRefs(featureFlagsStore);
      if (freePassFlag.value) {
        return "You have a free pass. Enjoy!"
      }

      if (userStore.isFounder) return "You are a founder member, with a free subscription. Thank you.";

      if (!this.haveSubscriptionsLoaded) return "Loading your account information...";

      if (this.subscription?.status === "active") return `You have a Monthly subscription. Thank you.`;
      if (this.subscription?.status === "canceled") return `You have cancelled your subscription. Please come back soon.`;

      if (userStore.isInFreeTrial) {
        return `Your free trial will continue until ${userStore.freeTrialExpiryDate?.toLocaleString(
          { weekday: "long", month: "long", day: "2-digit" }
        )}.`;
      }

      return `To continue using TaskAngel, please click on the subscribe button.`;

    },
  },

  actions: {
    clearSubscriptions() {
      this.subscription = null;
      this.haveSubscriptionsLoaded = false;
    },

    subscribeToFirestoreSubscriptions() {
      try {

        Logger.log('subscribeToSubscriptions starts');

        if (firebaseSubscriptionsUnsubscribe) return;
        const userStore = useUserStore();
        if (!userStore.user) return;

        Logger.log(`subscribeToFirestoreSubscriptions sees subscriptionsCollectionRef is ${subscriptionsCollectionRef}`)

        firebaseSubscriptionsUnsubscribe = onSnapshot(subscriptionsCollectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
          try {

            snapshot.docs.forEach((doc) => {
              const data = doc.data();
              const id = doc.id;
              const subscription = SubscriptionObject.copyFromAnyObject(data, id);
              Logger.log(`SubscribeToSubscriptions has got a subscription: ${JSON.stringify(subscription)}`)
              if (subscription.id)
                this.subscription = subscription;
              Logger.log(`SubscribeToSubscriptions has set subscription in state to ${JSON.stringify(subscription)}`)
            })

            this.haveSubscriptionsLoaded = true;
            return true;

          } catch (error) {
            Logger.logError('Error while processing a subscriptions', error)
            throw error;
          }

        });

        this.isFirestoreSubscribed = true;
      } catch (error) {
        Logger.logError('Error while subscribing to subscriptions', error)
        throw error
      }

    },

    loadSubscriptions() {

      if (this.haveSubscriptionsLoaded) return;
      const userStore = useUserStore();
      if (!userStore.user) return;
      if (this.firebaseSubscriptionsUnsubscribe) return;
      this.subscribeToFirestoreSubscriptions();

    },

    unsubscribeSubscriptions() {
      if (this.firebaseSubsUnsubscribe) {
        this.firebaseSubsUnsubscribe();
        this.firebaseSubsUnsubscribe = null;
      }
      this.isSubscribing = false;
    },

    async writeCheckoutSessionToFirestore(session: any) {
      try {
        const checkoutCollectionRef = checkoutSessionsCollectionRef;
        Logger.log(`writeCheckoutSessionToFirestore sees session.id is ${session.id} and collectionRef is ${JSON.stringify(checkoutCollectionRef)}`)
        const docRef = doc(checkoutCollectionRef, session.id)
        Logger.log(`writeCheckoutSessionToFirestore sees docRef for session id ${session.id} is ${JSON.stringify(docRef)}`)

        await setDoc(docRef, session)
        Logger.log(`writeCheckoutSessionToFirestore has written session ${JSON.stringify(session)}`)

        return docRef;
      } catch (error) {
        Logger.logError('Error writing checkout session for Firestore', error)
        throw error;
      }
    },

    async callSubscriptionCheckout() {

      const checkoutSession = {
        price: "price_1KDBcUJhaCQ1c8lR6ksI90lx",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        id: generateUUID(),
      }

      const docRef = await this.writeCheckoutSessionToFirestore(checkoutSession);

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          Logger.logError('Error while processing snapshot for checkout', error)
          throw error;
        }
        if (url) {
          window.location.assign(url);
        }
      }
      )
    },

    async startFreeTrial() {
      const userStore = useUserStore();
      if (!userStore.user?.uid) {
        return;
      }

      if (!userStore.user) return;

      const session = {
        id: generateUUID(),
        mode: "subscription",
        price: Constants.MONTHLY_PRICE_ID,
        success_url: "https://app.taskangel.com",
        cancel_url: "https://app.taskangel.com",
        subscription_data: {
          trial_settings: {
            end_behavior: {
              missing_payment_method: "cancel",
            },
          },
          trial_period_days: Constants.TRIAL_PERIOD_DAYS,
        },
        payment_method_collection: "if_required",
      }

      const docRef = await this.writeCheckoutSessionToFirestore(session)

      onSnapshot(docRef, async (snap) =>  {

        const snapData = snap.data();
        const error = snapData?.error;
        const sessionId = snapData?.sessionId;
        if (error) {
          Sentry.captureException(error);
        }
        if (sessionId) {
          const stripe = await loadStripe(Constants.STRIPE_PUBLISHABLE_API_KEY);
          await stripe?.redirectToCheckout({ sessionId });
        }
      });
    },

    async gotoCheckout() {
      const userStore = useUserStore();
      if (!userStore.user) {
        throw new Error("To subscribe you must be logged in");
      }

      const session = {
        id: generateUUID(),
        mode: "subscription",
        price: Constants.MONTHLY_PRICE_ID,
        success_url: window.location.origin, // 'https://app.taskangel.com',
        cancel_url: window.location.origin, // 'https://app.taskangel.com',
      }

      const docRef = await this.writeCheckoutSessionToFirestore(session)
      docRef.onSnapshot(async (snap) => {
        const { error, url } = snap.data();
        if (error) {
          Logger.logError('Error processing subscription change', error)
          throw error;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    },
  },
});
