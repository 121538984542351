

// import { useStore } from "vuex";
import { defineComponent, onMounted, ref } from "vue";
import { alertController } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useNotificationsStore } from "@/stores/useNotifications";

export default defineComponent({
  name: "NotificationContainer",

  setup() {
    const store = useNotificationsStore();
    // computed properties
    const { notifications } = storeToRefs(store)
    const { removeNotification } = store
    const listKey = ref(0); // add into notification ids to force refresh

    const showNotifications = async () => {
  
        // console.log(
        //   `NotificationContainer sees notifications: ${JSON.stringify(
        //     notifications
        //   )}`
        // );

        listKey.value += 1;

        // show the first notification as an alert
        if (notifications.value.length > 0) {
          const notification: any = notifications.value[0];

          const alert = await alertController.create({
            header: "TaskAngel",
            message: notification?.message,
            buttons: [
              {
                text: "OK",
                handler: async () => {
                  removeNotification(notification);
                  listKey.value += 1;
                },
              },
            ],
          });
          return alert.present();
        }
      
    }

    // SUBSCRIPTIONS
    /** subscribe to Pinia store to see if notifications change.
     * 
     * This unsubscribes automatically when component is unmounted
     *
     * */

    store.$subscribe(() => {
      //  console.log(`NotificationContainer sees state change with mutation type: ${mutation.type}`);
        showNotifications()   
    })


    // const unsubscribe = store.subscribe(async (mutation) => {
    //   // console.log(`NotificationContainer sees state change with mutation type: ${mutation.type}`);
    //   if (mutation.type.includes("Notification")) {
    //     showNotifications()
    //   }
    // });
    onMounted(async () => {
      await showNotifications()
    })
    // onBeforeUnmount(() => {
    //   unsubscribe(); // stop listening
    // });
    return {
      notifications,
      listKey,
    };
  },
});
