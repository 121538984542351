import { DateTime } from 'luxon';
import * as Sentry from "@sentry/vue";
export class DateHelper {


    /**
     * Converts a date from any format and always returns a valid ISO date string
     * 
     * @remarks
     * If the format is JSON {seconds: 12345, nanoseconds} catch that and convert it just using the seconds
     * If the format is invalid, return undefined
     * @param source: any 
     */
    static convertDateToISO(source: any): string | undefined {
        if (!source) {
            return undefined;
        }
        try {
            const date = DateTime.fromISO(source);
            return date.toISO();

        } catch {
            // console.log(`Not a valid date format: ${source}, try Timestamp`)
            try {
                // try again as a timestamp in format Timestamp(seconds=1234567890, nanoseconds:...
                const tsec = source["seconds"];
                if (tsec) {
                    // console.log(`timestamp seconds property is: ${tsec}`);
                    // console.log(`Seconds parsed as number is: ${tsec}`);
                    const t = DateTime.fromSeconds(tsec);

                    const result = t.toISO();
                    // console.log(`converted date is: ${result}`);
                    return result;
                } else {
                    // console.log(`JSON could not extract seconds from: ${JSON.stringify(tsec)}`);
                }
                // console.log(`JSON could not parse an object from: ${source}`);
                return undefined;
            }
            catch (error) {
                // console.log(`JSON could not parse an object from: ${source}, error is ${error.message}`);
                return undefined;
            }
        }
    }

    /**
      * Converts a date from any format and always returns a valid DateTime or undefined
      * 
      * @remarks
      * If the format is JSON {seconds: 12345, nanoseconds} catch that and convert it just using the seconds
      * If the format is invalid, return undefined
      * @param source: any 
      */
    static copyDate(source: any): DateTime | undefined {
        if (!source) {
            // console.log(`DateHelper.copyDate sees no date and returns undefined`);
            return undefined;
        }
        if (Object.prototype.toString.call(source) === "[object DateTime]") {
            // it is a date
            if (isNaN(source.year)) {  // d.valueOf() could also work
                // date is not valid

            } else {
                // date is valid
                return source;
            }
        } else {
            // not a date
            // try parsing it

            try {
                const date = DateTime.fromISO(source);
                if (!isNaN(date.year)) {  // d.valueOf() could also work
                    // date is valid
                    // console.log(`TaskObject sees a valid date ${source} and returns ${date}`);
                    return date;
                }
                if (date) {
                    // console.log(`TaskObject sees an invalid date ${source}. Trying timestamp`);
                    return this.copyDateFromTimestamp(source);
                }

            } catch (error) {
                
                // console.log(`TaskObject: JSON could not parse date from: ${source}, error is ${error.message}`);
                Sentry.captureMessage(`TaskObject: JSON could not parse date from: ${source}, error is ${error.message}`)
                return undefined;
            }
        }
    }

    static copyDateFromTimestamp(source: any): DateTime | undefined {
        // console.log(`DateHelper copyDateFromTimestamp is working on ${JSON.stringify(source, undefined, 2)}`)
        const tsec = source.seconds;
        if (tsec) {
            // console.log(`timestamp seconds property is: ${tsec}`);
            // console.log(`Seconds parsed as number is: ${tsec}`);
           
            const result = DateTime.fromSeconds(tsec);
            // console.log(`TaskObject copied date from ${JSON.stringify(tsec)} to: ${result}`);
            return result;
        } 
        // console.log(`TaskObject: JSON could not parse a date from: ${source}`);
        return undefined;
    }

    /**
     * Gets now as a local date/time
     */
    static get now() {
        return DateTime.local();
    }

    /**
     * Gets the date component of a Date object, according to the local timezone
     * 
     */
    static dateOnly(date: DateTime): DateTime{

        const result = date.startOf("day");
        return result;
    }

    static get today(): DateTime {
        const result = DateTime.local().startOf("day");
        return result;
    }
}