// set up the model

// import { Constants } from '@/constants/constants';
// import { Constants } from "@/constants/constants";
import { DateHelper } from "@/helpers/DateHelper";
import { DateTime } from "luxon";
// import { Priority } from './Priority';
// import { Status } from "./Status";

export interface SubscriptionType {
  id?: string;
  title?: string;
  role?: string;
  currentPeriodStart?: DateTime;
  currentPeriodEnd?: DateTime;
  trialEnd?: DateTime;
  status?: string;

  // copyToSimpleObject(): any;
}

export class SubscriptionObject implements SubscriptionType {
  id?: string;
  title?: string;
  role?: string;
  currentPeriodStart?: DateTime;
  currentPeriodEnd?: DateTime;
  trialEnd?: DateTime;
  status?: string;

  // copyToSimpleObject() {
  //   const defaults = {
  //     id: "",
  //     title: "",
  //     role: "",
  //     currentPeriodStart: "",
  //     currentPeriodEnd: "",
  //     status: "",
  //   };

    // const result = {
    //   ...defaults,
    //   ...this,
    // };

    // console.log(`Subscription CopyToSimpleObject converts ${JSON.stringify(this)} to this: ${JSON.stringify(result)}`)

  //   return result;
  // }

  // copyToFirestoreObject() {
  //   const result = {
  //     id: this.id ?? "",
  //     title: this.title ?? "",
  //     role: this.role ?? "",
  //     currentPeriodStart: this.currentPeriodStart?.toISO() ?? "",
  //     currentPeriodEnd: this.currentPeriodEnd?.toISO() ?? "",
  //     trialEnd: this.trialEnd.toISO() ?? ""
  //   };

  //   return result;
  // }

  /** Makes a SubscriptionObject using the properties of any object
   * 
   * @remarks We call this from the subscription modeule to unpack a subscription object while loading subscriptions from firestore.
   */
  static copyFromAnyObject(source: any, withId?: string): SubscriptionObject {

    // console.log(`SubscriptionObject copyFromAnyObject is copying from source ${JSON.stringify(source,undefined, 2)}`)
 
    const t = new SubscriptionObject();
    if (withId) {
      t.id = withId;
    } else {
      t.id = source.id;
    }
    if (source.title) {
      t.title = source.title;
    }
    
    if (source.role) {
      t.role = source.role;
    }
    
    if (source.currentPeriodStart) {
      t.currentPeriodStart = DateHelper.copyDate(source.currentPeriodStart);
    }
    if (source.currentPeriodEnd) {
      t.currentPeriodEnd = DateHelper.copyDate(source.currentPeriodEnd);
    }

    if(source.current_period_start?.seconds) {
      t.currentPeriodStart = DateHelper.copyDateFromTimestamp(source.current_period_start);
    }

    if(source.current_period_end?.seconds) {
      t.currentPeriodEnd = DateHelper.copyDateFromTimestamp(source.current_period_end);
    }

    if(source.trial_end?.seconds) {
      t.trialEnd = DateHelper.copyDateFromTimestamp(source.trial_end);
    }

    if(source.status){
      t.status = source.status
    }

    // console.log(`Subscription CopyFromAnyObject converts firebase subscription to this: ${JSON.stringify(t, undefined, 2)}`)
    return t;
  }
}
