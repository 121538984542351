import { Constants } from "@/constants/constants";
import { collection, doc, DocumentData, onSnapshot, QueryDocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

import { db, tasksCollectionRef } from "@/firebase/firebase-auth";
import { TaskObject } from "@/models/TaskObject";
import { AllFilter } from "@/models/filters/AllFilter";
import { IsCompleteFilter } from "@/models/filters/IsCompleteFilter";
import { DateTime } from "luxon";
import { defineStore, storeToRefs } from "pinia";

import { NotFilter } from "@/models/filters/NotFilter";
import { PriorityFilter } from "@/models/filters/PriorityFilter";

import { Logger } from "@/helpers/Logger";
import { RepeatHelper } from "@/helpers/RepeatHelper";
import { SortDescriptor, Sorter } from "@/helpers/Sorter";
import { ContextObject } from "@/models/ContextModel";
import { FolderObject } from "@/models/FolderModel";
import { GoalObject } from "@/models/GoalModel";
import { Priority } from "@/models/Priority";
import { TaskGroup } from "@/models/TaskGroup";
import { DateFilterOption } from "@/models/filters/DateFilterOption";
import { Filter } from "@/models/filters/Filter";
import { StartDateFilter } from "@/models/filters/StartDateFilter";
import { TextFilter } from "@/models/filters/TextFilter";
import { useFiltersStore } from "@/stores/useFiltersStore";
import { useSettingsStore } from "@/stores/useSettingsStore";
import * as Sentry from "@sentry/vue";
import { setDoc, writeBatch } from "firebase/firestore";
import Papa from "papaparse";
import TurndownService from "turndown";
import { v4 as generateUUID } from 'uuid';
import { useContextsStore } from "./useContextsStore";
import { useFeatureFlagsStore } from "./useFeatureFlagsStore";
import { useFoldersStore } from "./useFoldersStore";
import { useGoalsStore } from "./useGoalsStore";
import { useUserStore } from "./useUserStore";

let firebaseTasksUnsubscribe: { (): void; (): void } | null = null;

function groupByPriority(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.priority || "Low";
    const groupTitle = value;
    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(value, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}

function groupByUrgency(tasks: TaskObject[], autoUrgency: boolean): TaskGroup[] {
  const result = Array<TaskGroup>();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.getUrgency(autoUrgency);
    const groupTitle = value;
    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(value, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}

function groupByTitle(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.title;
    const groupTitle = `Title: ${value ?? "No title"}`;
    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(groupTitle, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}


function groupByFolder(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  const foldersStore = useFoldersStore();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.folderId;
    const groupTitle = `Folder: ${foldersStore.folderFromId(value)?.title ?? "No folder"}`;

    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(groupTitle, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}


function groupByGoal(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  const goalsStore = useGoalsStore()
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.goalId;
    const groupTitle = `Goal: ${goalsStore.goalFromId(value)?.title ?? "No goal"}`;

    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(groupTitle, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}

function groupByContext(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  const contextsStore = useContextsStore();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.contextId;
    const groupTitle = `Context: ${contextsStore.contextFromId(value)?.title ?? "No context"
      }`;

    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(groupTitle, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}

function groupByStart(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.start;
    const groupTitle = `Start: ${value?.toFormat("DD") ?? "Undated"}`;


    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(groupTitle, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }
  return result;
}

function groupByDue(tasks: TaskObject[]): TaskGroup[] {
  const result = Array<TaskGroup>();
  let currentGroup: TaskGroup;
  for (let i = 0; i < tasks.length; ++i) {
    const task = tasks[i];
    const value = task.due;
    const groupTitle = `Due: ${value?.toFormat("DD") ?? "Undated"}`;

    if (!currentGroup?.title || currentGroup.title !== groupTitle) {
      currentGroup = new TaskGroup(groupTitle, value);
      result.push(currentGroup);
    }
    currentGroup.items.push(task);
  }

  return result;
}


const getRepeatParameterFromICal = (iCal: string, key: string): string => {
  let result = "";
  const index = iCal.indexOf(key);
  if (index < 0) {
    return "";
  }
  const remainderIndex = index + key.length + 1;
  const terminatorIndex = iCal.indexOf(";", remainderIndex);
  if (terminatorIndex < 0) {
    result = iCal.substring(remainderIndex);
    return result.trim();
  }
  result = iCal.substring(remainderIndex, terminatorIndex);
  return result.trim();
};


export const useTasksStore = defineStore("tasksStore", {
  state: () => ({
    tasks: Array<TaskObject>(),
    isLoadingTasks: false,
    haveTasksLoaded: false,
    mostRecentId: "",
    isFirestoreSubscribed: false,
    csvHeaders: Array<string>(),
    csvFieldProperties: Array<string>(),
    csvTitleFieldName: "",
    importedCount: 0,
    importError: "",
    importAborted: false,
    isImportParsing: false,
    isImportProcessing: false,
    selectedTasks: Array<TaskObject>(),
    openParents: Array<TaskObject>(),
  }),

  getters: {

    getTasks: (state) => {
      return state.tasks ?? [];
    },
    getUser: () => {
      try {
        const userStore = useUserStore();
        const user = userStore.user;
        return user
      } catch (error) {
        Logger.logError('Error in tasks store getUser getter', error)
        throw error
      }
    },

    taskFromId:
      (state) =>
        (id: string): TaskObject | undefined => {
          if (state.tasks?.length > 0) {
            for (let i = 0; i < state.tasks.length; i += 1) {
              if (state.tasks[i].id === id) {
                return state.tasks[i];
              }
            }
          }
          return undefined;
        },

    taskWithIdExists: (state) => {
      (id: string): boolean => {
        return !!state.tasks.find(item => item.id === id);
      }
    },

    allTasksCount(): number {
      return this.tasks?.length || 0;
    },

    countTasksFilteredBy() {
      const filters = useFiltersStore();
      const settings = useSettingsStore();
      return (filterId?: string): number => {
        const allTasks: [TaskObject] = this.tasks;

        if (allTasks.length < 1) {
          return 0;
        }

        if (useFeatureFlagsStore().newHamburgerMenuFlag) {
          let filter: Filter = filters.taskFilterFromId(
            filterId ?? Constants.ACTIVE_FILTER
          );
          if (filter) {
            switch (filterId) {
              case Constants.URGENCY_CRITICAL_NOW_FILTER:
                filter = new AllFilter("counting filter", [
                  filter,
                  filters.taskFilterFromId(Constants.ACTIVE_FILTER),
                ]);
                break;
              default:
                break;
            }
          }

          if (filter) {
            const result = allTasks.reduce((total, task) => {
              if (filter.isFiltered(task)) {
                total += 1;
              }
              return total;
            }, 0);

            return result;
          }
          return allTasks?.length ?? 0;
        }

        const compound = new AllFilter("Counting task filter");

        if (filterId && filterId != Constants.ALL_TASKS_FILTER) {
          const filter = filters.taskFilterFromId(filterId);

          if (filter) {
            compound.add(filter);
          }

        }

        if (filterId != Constants.COMPLETED_FILTER && !settings.showCompleted) {
          compound.add(new IsCompleteFilter(false));
        }

        if (
          filterId != Constants.PRIORITY_NEGATIVE_FILTER &&
          !settings.showNegative
        ) {
          compound.add(
            new NotFilter(
              "Not negative",
              new PriorityFilter(Constants.PRIORITY_NEGATIVE)
            )
          );
        }

        if (
          filterId != Constants.SHOW_FUTURE_STARTS &&
          !settings.showFutureStarts
        ) {
          compound.add(
            new NotFilter(
              "Not future start",
              new StartDateFilter(DateFilterOption.Future)
            )
          );
        }

        const result = allTasks.reduce((total, task) => {
          if (compound.isFiltered(task)) {
            total += 1;
          }
          return total;
        }, 0);

        return result;
      };
    },

    criticalTasksCount(): number {
      const result = this.countTasksFilteredBy(
        Constants.URGENCY_CRITICAL_NOW_FILTER
      );
      return result;
    },

    activeTasksCount(): number {
      const result = this.countTasksFilteredBy();
      return result;
    },

    completedTasksCount(): number {

      const result = this.countTasksFilteredBy(Constants.COMPLETED_FILTER);
      return result;
    },

    todaysTasksCount(): number {
      const result = this.countTasksFilteredBy(Constants.DUE_TODAY_FILTER);
      return result;
    },

    areTasksEqual() {
      return (task1: TaskObject, task2: TaskObject) => {
        const result = JSON.stringify(task1) == JSON.stringify(task2);
        return result;
      }

    },

    taskIsSelected() {
      return (task: TaskObject) => {
        return !!this.selectedTasks.find((item: TaskObject) => task.id === item.id)
      }
    },

    taskIsOpenParent() {
      return (task: TaskObject) => {
        return !!this.openParents.find((item: TaskObject) => task.id === item.id)
      }
    },

    subtasksOfTask() {
      return (task: TaskObject): TaskObject[] => {
        if (!this.tasks) {
          return [];
        }
        const id = task?.id
        const result: TaskObject[] = this.tasks.filter((task: TaskObject) => {
          return !!task.parentId && task.parentId === id;
        });
        return result;
      };
    },


    activeSubtasksOfTask() {
      return (task: TaskObject): TaskObject[] => {
        if (!this.tasks) {
          return [];
        }
        const id = task?.id
        const result: TaskObject[] = this.tasks.filter((subtask: TaskObject) => {
          return !!subtask.parentId && subtask.parentId === id && !subtask.completed;
        });
        return result;
      };
    },



    filteredSubtasksOfTask() {
      return (task: TaskObject): TaskObject[] => {
        if (!this.filteredTasks) {
          return [];
        }
        const id = task?.id
        const result = this.filteredTasks.filter((item: { parentId: string; }) => item.parentId === id);
        return result;
      };
    },

    hasSubtasks() {
      return (task: TaskObject) => {
        return !!this.tasks.find((t: TaskObject) => t.parentId === task.id);
      }
    },

    descendantsOfTaskWithId() {
      return (id: string) => {
        const subtasks = this.subtasksOfTask(id);
        if (!subtasks) {
          return [];
        }

        let result = new Array<TaskObject>();

        if (subtasks?.length > 0) {
          for (let i = 0; i < subtasks.length; i += 1) {
            const sub: TaskObject = subtasks[i];
            result.push(sub);
            const subsubs = this.descendantsOfTaskWithId(sub.id);
            if (subsubs) {
              if (subsubs.length > 0) {
                result = result.concat(subsubs);
              }
            }
          }
          return result;
        }

        return result;
      };
    },

    ancestorIdsOfTaskWithId() {
      return (taskId: string): string[] => {

        const task = this.taskFromId(taskId)
        if (!task || !task.parentId) {
          return Array<string>();
        }

        const parentAncestorIds = this.ancestorIdsOfTaskWithId(task.parentId)
        if (parentAncestorIds.length > 0) {
          return [task.parentId, ...parentAncestorIds]
        }
        return [task.parentId]
      }
    },

    hasImportedTasks(): boolean {
      if (!this.tasks) return false;
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].imported) return true
      }
      return false;
    },

    tasksReadyToPurge(): TaskObject[] {
      let result = new Array<TaskObject>();

      for (
        let i = 0;

        i < this.tasks?.length;
        i++
      ) {

        const task: TaskObject = this.tasks[i];
        if (
          task.completed

        ) {
          if (!task.parentId) {
            result.push(task);

            const descendants = this.descendantsOfTaskWithId(task.id);
            if (descendants.length > 0) {
              result = result.concat(descendants);
            }
          }
        }
      }

      return result;
    },
    repeatDisplayString: () => (task: TaskObject) => {
      const intervalString = getRepeatParameterFromICal(task.repeat, "INTERVAL");
      const daysString = getRepeatParameterFromICal(task.repeat, "BYDAY");
      const interval =
        intervalString.length == 0 ? 0 : parseInt(intervalString, 10);


      const result =
        (!task.repeat && "Never") ||
        (task.repeat.includes("PARENT") && "With Parent") ||
        (task.repeat.includes("COMP") &&
          ((task.repeat.includes("DAILY") &&
            ((interval === 0 && "Tomorrow") || `Next ${interval} days`)) ||
            (task.repeat.includes("WEEKLY") &&
              ((interval === 0 && "Next week") || `Next ${interval} weeks`)) ||
            (task.repeat.includes("MONTHLY") &&
              ((interval === 0 && "Next month") ||
                `Next ${interval} months`)) ||
            (task.repeat.includes("YEARLY") &&
              ((interval === 0 && "Next year") || `Next ${interval} years`)) ||
            (task.repeat.includes("BYDAY") &&
              !!daysString &&
              ((daysString === "MO,TU,WE,TH,FR" && "Next weekday") ||
                (daysString === "SU,SA" && "Next weekend day"))))) ||
        // not COMP

        (task.repeat.includes("DAILY") &&
          ((interval === 0 && "Every day") || `Every ${interval} days`)) ||
        (task.repeat.includes("WEEKLY") &&
          ((interval === 0 && "Weekly") ||
            (interval === 2 && "Biweekly") ||
            `Every ${interval} weeks`)) ||
        (task.repeat.includes("MONTHLY") &&
          ((interval === 0 && "Every month") ||
            (interval === 3 && "Quarterly") ||
            (interval === 6 && "Semiannually") ||
            `Every ${interval} months`)) ||
        (task.repeat.includes("YEARLY") &&
          ((interval === 0 && "Every year") || `Every ${interval} years`)) ||
        (task.repeat.includes("BYDAY") &&
          !!daysString &&
          ((daysString === "MO,TU,WE,TH,FR" && "Weekdays") ||
            (daysString === "SU,SA" && "Weekends"))) ||
        task.repeat;


      return result;
    },
    unfilteredTasks(): TaskObject[] {
      if (!this.tasks) {
        return [];
      }
      return this.tasks;
    },
    filteredTasks(): TaskObject[] {
      if (!this.unfilteredTasks) {
        return [];
      }

      const result = this.unfilteredTasks.filter(task => this.isFiltered(task))
      return result;
    },

    filteredTasksHiddenByParents(): TaskObject[] {
      if (!this.unfilteredTasks) {
        return [];
      }

      const result = this.unfilteredTasks.filter(task => this.isFilteredWithoutParent(task))
      return result;
    },

    finalFilter() {
      const filtersStore = useFiltersStore();
      const selectedFilter = filtersStore.selectedFilter;
      const { tasksSearchText } = storeToRefs(useSettingsStore());
      const result = tasksSearchText.value ? new AllFilter('Final Filter', [selectedFilter, new TextFilter(this.tasksSearchText.value)]) : selectedFilter
      return result;

    },

    isFiltered() {
      return (task: TaskObject) => {
        const filtersStore = useFiltersStore();
        const selectedFilter = filtersStore.selectedFilter;
        if (!selectedFilter.isFiltered(task)) return false;
        const settings = useSettingsStore();
        const searchText = settings.tasksSearchText;
        if (searchText) {
          const searchFilter = new TextFilter(searchText)
          return searchFilter.isFiltered(task)
        }
        return true;
      }
    },

    isFilteredWithoutParent() {
      return (task: TaskObject) => {

        if (task.parentId) {
          const parent = this.taskFromId(task.parentId);
          if (!!parent && this.isFiltered(parent)) return false;
        }

        return this.isFiltered(task)

      }
    },

    sortDescriptors() {
      const { groupBy,
        groupDirection, sort1By,
        sort1Direction,
        sort2By,
        sort2Direction,
        sort3By,
        sort3Direction } = storeToRefs(useSettingsStore())

      const result = Array<SortDescriptor>();
      const asc = groupDirection.value === "ascending";
      if (groupBy.value) {
        const desc: SortDescriptor = {
          property: groupBy.value.toLowerCase(),
          ascending: asc,
        };
        result.push(desc);
      }

      const sort1: string = sort1By.value.toLowerCase();
      if (sort1) {
        const asc = sort1Direction.value === "ascending";
        const desc: SortDescriptor = { property: sort1, ascending: asc };
        result.push(desc);
      }

      const sort2: string = sort2By.value.toLowerCase();
      if (sort2) {
        const asc = sort2Direction.value === "ascending";
        const desc: SortDescriptor = { property: sort2, ascending: asc };
        result.push(desc);
      }

      const sort3: string = sort3By.value.toLowerCase();
      if (sort3) {
        const asc = sort3Direction.value === "ascending";
        const desc: SortDescriptor = { property: sort3, ascending: asc };
        result.push(desc);
      }

      return result;
    },

    sortedFilteredTasks() {

      return (hiddenByParents) => {

        if (hiddenByParents && !this.filteredTasksHiddenByParents) {
          return [];
        }
        if (!hiddenByParents && !this.filteredTasks) {
          return [];
        }
        const { groupBy,
          groupDirection, sort1By,
          sort1Direction,
          sort2By,
          sort2Direction,
          sort3By,
          sort3Direction, autoUrgency } = storeToRefs(useSettingsStore())
        const sortDescriptors = Array<SortDescriptor>();
        const asc = groupDirection.value === "ascending";
        if (groupBy.value) {
          const desc: SortDescriptor = {
            property: groupBy.value.toLowerCase(),
            ascending: asc,
          };
          sortDescriptors.push(desc);
        }

        const sort1: string = sort1By.value.toLowerCase();
        if (sort1) {
          const asc = sort1Direction.value === "ascending";
          const desc: SortDescriptor = { property: sort1, ascending: asc };
          sortDescriptors.push(desc);
        }

        const sort2: string = sort2By.value.toLowerCase();
        if (sort2) {
          const asc = sort2Direction.value === "ascending";
          const desc: SortDescriptor = { property: sort2, ascending: asc };
          sortDescriptors.push(desc);
        }

        const sort3: string = sort3By.value.toLowerCase();
        if (sort3) {
          const asc = sort3Direction.value === "ascending";
          const desc: SortDescriptor = { property: sort3, ascending: asc };
          sortDescriptors.push(desc);
        }

        const copyForSort: TaskObject[] = hiddenByParents ? [...this.filteredTasksHiddenByParents] : [...this.filteredTasks]
        Sorter.sortTasksInPlace(copyForSort, autoUrgency.value, sortDescriptors)

        return copyForSort;
      }

    },
    groupedSortedFilteredTasks(): TaskGroup[] | TaskObject[] {
      const { autoUrgency, useMYN, groupBy } = storeToRefs(useSettingsStore());

      if (!this.tasks || this.tasks.length === 0) return [];
      const hiddenByParents = true;
      const tasks: TaskObject[] = this.sortedFilteredTasks(hiddenByParents);

      const result =
        ((!groupBy.value || groupBy.value === "None") && tasks) ||
        (groupBy.value === "Priority" && !useMYN.value && groupByPriority(tasks)) ||
        (groupBy.value === "Priority" && useMYN.value && groupByUrgency(tasks, autoUrgency.value)) ||
        (groupBy.value === "Urgency" && useMYN.value && groupByUrgency(tasks, autoUrgency.value)) ||
        (groupBy.value === "Urgency" && !useMYN.value && groupByPriority(tasks)) ||
        (groupBy.value === "Title" && groupByTitle(tasks)) ||
        (groupBy.value === "Folder" && groupByFolder(tasks)) ||
        (groupBy.value === "Goal" && groupByGoal(tasks)) ||
        (groupBy.value === "Context" && groupByContext(tasks)) ||
        (groupBy.value === "Start" && groupByStart(tasks)) ||
        (groupBy.value === "Due" && groupByDue(tasks)) ||
        [];


      return result;

    },
  },
  actions: {
    subscribeToFirestoreTasks() {
      try {

        firebaseTasksUnsubscribe = onSnapshot(tasksCollectionRef, async (snap: QuerySnapshot) => {
          try {

            if (this.haveTasksLoaded) {
              snap.docChanges().forEach((change) => {
                this.processFirestoreChange(change)
              })
              return;
            }

            this.tasks = this.convertFirestoreDocs(snap.docs);

            if (!this.tasks?.length) {
              await this.addWelcomeTask();
            }

            this.haveTasksLoaded = true;
            this.isLoadingTasks = false;
            return true;
          } catch (error) {
            Logger.logError(`Error when subscribing to firestore tasks`, error)
            throw error
          }

        });

        this.isFirestoreSubscribed = true;
      } catch (error) {
        Logger.logError(`subscribeToFirestoreTasks gets error while subscribing`, error)
        throw error
      }

    },
    processFirestoreChange(change) {
      const data = change.doc.data();
      const id = change.doc.id;
      const task = TaskObject.fromAnyObject(data, id);
      if (change.type === "added") this.processFirestoreAdded(task)
      else if (change.type === "modified") this.processFirestoreModified(task);
      else if (change.type === "removed") this.processFirestoreRemoved(task)
    },

    processFirestoreAdded(task: TaskObject) {
      const exists = !!this.taskFromId(task.id);
      if (!exists) this.tasks.push(task);
    },

    processFirestoreModified(task: TaskObject) {
      task.modified = DateTime.utc();
      const taskIndex = this.tasks.findIndex(
        (t: TaskObject) => t.id == task.id
      );
      if (taskIndex < 1) return;

      this.tasks.splice(taskIndex, 1);
      this.tasks.push(task);
    },

    processFirestoreRemoved(task: TaskObject) {
      const taskIndex = this.tasks.findIndex(
        (t: TaskObject) => t.id == task.id
      );
      if (taskIndex < 1) {
        return;
      }
      this.tasks.splice(taskIndex, 1);
    },

    convertFirestoreDocs(docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]) {
      const result = Array<TaskObject>();
      docs.forEach((doc: any) => {
        const task = this.convertFirestoreDoc(doc)
        result.push(task);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const data = doc.data();
      const id = doc.id;
      return TaskObject.fromAnyObject(data, id);
    },

    async addWelcomeTask() {
      const welcomeTask = new TaskObject("Welcome to TaskAngel!")
      let note = "__Welcome to TaskAngel__  \nNow you can have more success at work 👍 and more fun at home 😎  \n"
      note += "\n\n---\n\n"
      note += "This is your first task. To go back to your task list, tap the back button above. \n"
      note += "For more help and support, please contact us at https://www.taskangel.com/contact"

      welcomeTask.note = note;
      welcomeTask.priority = Priority.Medium;

      await this.addTask(welcomeTask)
    },
    loadTasks() {

      this.isLoadingTasks = true;
      if (this.haveTasksLoaded) {
        return;
      }
      const userStore = useUserStore();
      if (!userStore.user) {
        return;
      }
      if (this.firebaseTasksUnsubscribe) {
        return;
      }

      this.subscribeToFirestoreTasks();

    },

    unsubscribeTasks() {
      if (firebaseTasksUnsubscribe) {
        firebaseTasksUnsubscribe();
        this.haveTasksLoaded = false;
      }
    },

    clearTasks() {
      this.tasks = [];
      this.haveTasksLoaded = false;
    },

    selectTask(task: TaskObject) {
      if (this.selectedTasks.find((item: TaskObject) => task.id == item.id)) {
        return;
      }
      this.selectedTasks.push(task);
    },

    selectTasks(tasks: TaskObject[]) {
      this.selectedTasks = [...this.selectedTasks, ...tasks]
    },

    deselectTask(task: TaskObject) {
      this.selectedTasks = this.selectedTasks.filter(item => item.id != task.id)
    },

    async adoptSelectedTasksInto(newParent: TaskObject) {

      for (const t of this.selectedTasks) {
        if (t.id != newParent.id) t.parentId = newParent.id;
      }

      await this.saveTasks(this.selectedTasks)
      this.clearSelectedTasks();
    },

    clearSelectedTasks() {
      this.selectedTasks = [];
    },

    setParentOpen(parent: TaskObject) {
      if (this.openParents?.find((item: TaskObject) => parent.id == item.id)) {
        return;
      }
      this.openParents.push(parent);
    },

    setParentClosed(parent: TaskObject) {
      this.openParents = this.openParents.filter((item: TaskObject) => item.id != parent.id)
    },

    setAllParentsClosed() {
      this.openParents = [];
    },

    async addTask(task: TaskObject) {
      task.id = task.id ?? generateUUID();
      this.saveTask(task);
      await this.saveTaskToFirestore(task);
    },

    async saveTask(task: TaskObject) {
      this.saveTaskToState(task);
      await this.saveTaskToFirestore(task);

    },

    async saveTaskToFirestore(task: TaskObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "tasks")
      const docRef = doc(collectionRef, task.id)
      const obj = task.toFirestoreObject();
      await setDoc(docRef, obj)
    },

    addTaskToState(task: TaskObject) {
      const taskExists = !!this.taskFromId(task.id)
      if (taskExists) return;
      this.tasks.push(task);
    },

    removeTaskFromState(task: TaskObject) {
      const taskIndex: number = this.tasks.findIndex(
        (t: TaskObject) => t.id == task.id
      );
      if (taskIndex >= 0) {
        this.tasks.splice(taskIndex, 1);
      }
    },

    saveTaskToState(task: TaskObject) {
      this.removeTaskFromState(task);
      this.addTaskToState(task);
    },

    saveTasksToState(tasks: TaskObject[]) {

      for (const task of tasks) {
        this.saveTaskToState(task);
      }

    },

    async saveTasksToFirestore(tasks: TaskObject[]) {
      const tasksCollectionRef = this.getUserTasksCollection();
      const chunks: TaskObject[][] = this.splitIntoChunks(tasks);

      for (const chunk of chunks) {
        const firestoreBatch = writeBatch(db);
        for (const task of chunk) {
          const firestoreTaskReference = doc(tasksCollectionRef, task.id)
          const fireStoreReadyTask = task.toFirestoreObject();
          firestoreBatch.set(firestoreTaskReference, fireStoreReadyTask)
        }
        await firestoreBatch.commit();
      }
    },

    async saveTasks(tasks: TaskObject[]) {
      if (!tasks) return;

      this.saveTasksToState(tasks);
      await this.saveTasksToFirestore(tasks)


    },

    splitIntoChunks(tasks: TaskObject[]): TaskObject[][] {
      const result = [];
      for (let i = 0; i < tasks.length; i += Constants.MAX_BATCH_SIZE) {
        result.push(tasks.slice(i, i + Constants.MAX_BATCH_SIZE))
      }
      return result
    },

    getUserTasksCollection() {
      const userStore = useUserStore();
      const userDocRef = userStore.userDocRef;
      return collection(userDocRef, "tasks")
    },

    updateExistingTaskInState(task: TaskObject) {
      const taskIndex: number = this.tasks.findIndex(
        (t: TaskObject) => t.id == task.id
      );
      this.tasks.splice(taskIndex, 1);
    },

    makeDefaultTask() {
      const task = new TaskObject();
      task.id = generateUUID();

      const useFilters = useFiltersStore();
      const { folderIdFromFilter, goalIdFromFilter, contextIdFromFilter } =
        storeToRefs(useFilters);

      const useSettings = useSettingsStore();
      const { newTasksStartToday } = storeToRefs(useSettings);

      task.folderId = folderIdFromFilter.value;
      task.goalId = goalIdFromFilter.value;
      task.contextId = contextIdFromFilter.value;
      if (newTasksStartToday.value) {
        task.start = DateTime.local();
      }
      task.priority = Priority.Medium;
      return task;
    },

    async repeatTask(task: TaskObject) {
      try {
        if (task.repeat == null || task.repeat === Constants.REPEAT_NONE) {
          return;
        }

        if (
          !!task.parentId &&
          !!task.repeat &&
          task.repeat === Constants.REPEAT_PARENT
        ) {
          return;
        }


        const fromDate = task.due ?? DateTime.local();
        const nextDate = RepeatHelper.calcRepeatingDate(task.repeat, fromDate);

        if (!nextDate) {
          return;
        }

        const dateChange = nextDate.diff(fromDate);
        const fromStartDate = task.start;
        const nextStartDate = fromStartDate?.plus(dateChange);

        const nextTask = TaskObject.fromAnyObject(task);
        nextTask.completed = undefined;
        nextTask.due = nextDate;
        nextTask.start = nextStartDate;
        nextTask.id = undefined;

        await this.addTask(nextTask);

        await this.repeatSubtasksOf(task, nextTask);
      } catch (error: any) {
        Logger.logError('Error rfepeating task', error)
        throw error;
      }
    },

    async repeatSubtasksOf(fromTask: TaskObject, toTask: TaskObject) {
      const subtasks = this.subtasksOfTask(fromTask.id).filter((sub: TaskObject) => {
        return sub.repeat && sub.repeat !== Constants.REPEAT_NONE;
      });

      if (!subtasks) {
        return;
      }

      try {
        const fromDate: DateTime = fromTask.due ?? DateTime.local();
        const toDate: DateTime = toTask.due ?? DateTime.local();
        const dateChange = toDate.diff(fromDate);

        for (const sub of subtasks) {
          const newSub = { ...sub };
          newSub.completed = undefined;
          const subFromDate = sub.due ?? DateTime.local();
          newSub.due = subFromDate.plus(dateChange);

          const fromStartDate = newSub.start;
          const nextStartDate = fromStartDate?.plus(dateChange);
          newSub.start = nextStartDate; // this could be null or undefined
          newSub.id = generateUUID();
          newSub.parentId = toTask.id;

          await this.addTask(newSub);

          await this.repeatSubtasksOf(sub, newSub);
        }
      } catch (error: any) {
        Logger.logError('Error while repeating subtasks', error)
        throw error
      }
    },

    removeTask(id: string) {
      this.removeTasks([id]);
    },

    async removeTasks(ids: string[]) {
      if (!ids) return;

      try {

        this.removeTasksFromStateWithIds(ids)

        let batch = writeBatch(db);
        let itemsInBatch = 0;
        const userStore = useUserStore();

        for (const id of ids) {

          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "tasks")
          const docRef = doc(collectionRef, id)

          batch.delete(docRef);
        }

        await batch.commit();

      } catch (error: any) {
        Logger.logError('Error in removeTasks', error)
        throw error;
      }
    },

    removeTasksFromStateWithIds(ids: string[]) {
      this.tasks = this.tasks.filter((task: TaskObject) => !ids.includes(task.id));
    },

    async purgeCompletedTasks() {
      const purging = this.tasksReadyToPurge;
      if (purging && purging.length > 0) {
        const ids: Array<string> = purging.map((d: TaskObject) => d.id ?? ""); // id's of tasks to purge
        return await this.removeTasks(ids); // remove them

      }
    },

    unsubscribeFirestore() {
      if (this.firebaseTasksUnsubscribe) {
        firebaseTasksUnsubscribe();
        this.isFirestoreSubscribed = false;
      }
    },

    doImport(file: File) {

      let headers = [];
      const properties = new Array<string>();
      this.importedCount = 0;
      let rowsImported = 0;
      let chunksStarted = -1;
      let chunksFinished = 0;
      let fieldCount = 0;

      this.isImportParsing = true;
      this.importError = '';


      Papa.parse(
        file,
        {
          header: false,
          worker: false,

          chunk: async function (results, parser) {

            const store = useTasksStore();

            chunksStarted++;
            store.isImportProcessing = true;

            const { data } = results;
            const batch = writeBatch(db);

            parser.pause()

            for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {

              if (chunksStarted === 0 && rowIndex === 0) { // this is the header row

                headers = data[rowIndex]
                fieldCount = headers.length;
                for (let f = 0; f < fieldCount; f++) {
                  const incomingHeaderTitle = headers[f].toLowerCase();
                  switch (incomingHeaderTitle) {
                    case "title":
                    case "task":
                      properties.push("title");
                      break;
                    case "completed":
                      properties.push('completed');
                      break;
                    case "folder":
                      properties.push("folder");
                      break;
                    case "start":
                      properties.push("start")
                      break;
                    case "startdate":
                      properties.push("startDate");
                      break;
                    case "starttime":
                      properties.push("startTime");
                      break;
                    case "due":
                      properties.push("due")
                      break;
                    case "duedate":
                      properties.push("dueDate");
                      break;
                    case "duetime":
                      properties.push("dueTime");
                      break;
                    case 'parentid':
                      properties.push('parentId')
                      break;
                    case 'id':
                      properties.push('id')
                      break;
                    case 'modified':
                      properties.push('modified')
                      break;
                    case 'added':
                      properties.push('added')
                      break;
                    default:
                      properties.push(incomingHeaderTitle)

                  }
                }

                if (!properties.find(prop => prop === "title")) {
                  store.importError = `Your import file must be in CSV format and its first row must have field headers including 'task' or 'title'`;
                  Sentry.captureException(new Error(`In doImport, no title in first row: ${JSON.stringify(properties)}`))
                  return;
                }
                rowsImported++;


              } else {

                const temp: any = {};
                const foldersStore = useFoldersStore();
                const { folderFromTitle, addFolder } = foldersStore;
                const contextsStore = useContextsStore();
                const { contextFromTitle, addContext } = contextsStore;
                const goalsStore = useGoalsStore();
                const { goalFromTitle, addGoal } = goalsStore;



                const fields = data[rowIndex];

                for (let f = 0; f < fields.length; f++) {
                  temp[properties[f]] = fields[f];
                }

                if (!temp.title) {
                  continue;
                }


                const task = new TaskObject(temp.title);

                task.id = generateUUID();

                if (temp.note) {
                  const turndownService: TurndownService = new TurndownService();
                  const md: string = turndownService.turndown(temp.note)
                  if (md) {
                    task.note = md
                  } else {
                    task.note = temp.note
                  }

                }

                if (temp.folder) {
                  const folder = folderFromTitle(temp.folder);
                  if (!folder) {
                    const newFolder = new FolderObject(temp.folder);
                    await addFolder(newFolder);
                    task.folderId = newFolder.id;
                  } else {
                    task.folderId = folder.id;
                  }
                }
                if (temp.context) {
                  const context = contextFromTitle(temp.context);
                  if (!context) {

                    const newContext = new ContextObject(temp.context);
                    await addContext(newContext);
                    task.contextId = newContext.id;
                  } else {
                    task.contextId = context.id;
                  }
                }
                if (temp.goal) {
                  const goal = goalFromTitle(temp.goal);
                  if (!goal) {
                    const newGoal = new GoalObject(temp.goal);
                    await addGoal(newGoal);
                    task.goalId = newGoal.id;
                  } else {
                    task.goalId = goal.id;
                  }
                }

                if (temp.start) {
                  task.start = DateTime.fromISO(temp.start)
                }

                if (temp.startDate) {
                  let startString = temp.startDate;
                  if (temp.startTime) {
                    startString = `${temp.startDate} ${temp.startTime}`;
                  }

                  task.start = DateTime.fromSQL(startString);

                }

                if (temp.completed) {
                  task.completed = DateTime.fromISO(temp.completed) ?? DateTime.fromSQL(temp.completed);
                }

                if (temp.due) {
                  task.due = DateTime.fromISO(temp.due)
                }

                if (temp.dueDate) {
                  let dueString = temp.dueDate;
                  if (temp.dueTime) {
                    dueString = `${temp.dueDate} ${temp.dueTime}`;
                  }
                  task.due = DateTime.fromSQL(dueString);
                }

                if (temp.repeat) {
                  task.repeat = temp.repeat;
                }

                if (temp.priority) {
                  switch (temp.priority) {
                    case "-1":
                    case "NEGATIVE":
                    case "Negative":
                    case "negative":
                      task.priority = Priority.Negative;
                      break;
                    case "0":
                    case "LOW":
                    case "Low":
                    case "low":
                      task.priority = Priority.Low;
                      break;
                    case "1":
                    case "MEDIUM":
                    case "Medium":
                    case "medium":
                      task.priority = Priority.Medium;
                      break;
                    case "2":
                    case "HIGH":
                    case "High":
                    case "high":
                      task.priority = Priority.High;
                      break;
                    case "3":
                    case "TOP":
                    case "Top":
                    case "top":
                      task.priority = Priority.Top;
                      break;
                  }
                }

                if (temp.star) {
                  task.isStarred = temp.star === "Yes";
                }

                if (temp.parentID) {
                  task.parentId = temp.parentID;
                }

                if (!temp.title) {

                  store.importError = `Line ${rowsImported + 1} of the import file has no title or task field.`;
                  return;
                }



                const userStore = useUserStore();
                if (!userStore.user?.uid) {
                  store.importError = "You must be logged in if you want to import tasks"
                  return false;
                }

                task.modified = task.added;

                if (store.tasks.length > userStore.tasksLimit) {
                  store.importError = `Import can't continue because you would exceed your limit of  ${userStore.tasksLimit} tasks`
                  parser.abort();
                  store.importAborted = true;
                  return;
                }

                const foundIndex = store.tasks.findIndex(
                  (t: TaskObject) => t.id == task.id
                );

                const found = foundIndex >= 0;
                const needsImport = true;

                if (needsImport) {

                  if (found) {
                    store.tasks.splice(foundIndex, 1);
                  }
                  task.added = task.added ?? DateTime.utc();
                  task.modified = task.modified ?? task.added;

                  task.imported = DateTime.utc();

                  store.tasks.push(task);

                  const userDocRef = doc(db, "users", userStore.user.uid);

                  if (!userDocRef) {
                    store.importError = 'Unable to import as your account details are not available'
                    parser.abort();
                    store.importAborted = true;
                    return false;
                  }

                  const obj = task.toFirestoreObject();

                  const collectionRef = collection(userDocRef, "tasks")
                  const docRef = doc(collectionRef, obj.id)
                  batch.set(docRef, obj)

                  store.importedCount++;

                }
                rowsImported++;
              }
            }

            await batch.commit();
            chunksFinished++;
            parser.resume();
            store.isImportProcessing = chunksStarted > chunksFinished;

          },
          complete: async (/*results, file, meta*/) => {

            const store = useTasksStore();
            store.isImportParsing = false;

          },
          error: async (err: Error | any) => {
            const store = useTasksStore();

            store.importError = err.message ?? err;
            store.importAborted = true;
          },
        }
      );

    },

    makeExport(): string {

      const store = useTasksStore();

      const foldersStore = useFoldersStore();
      const { folderFromId, } = foldersStore;
      const contextsStore = useContextsStore();
      const { contextFromId, } = contextsStore;
      const goalsStore = useGoalsStore();
      const { goalFromId, } = goalsStore;
      const tasksForExport = store.tasks.map(task => ({
        id: task.id,
        title: task.title,
        completed: task.completed?.toISO(),
        added: task.added,
        modified: task.modified?.toISO(),
        parentID: task.parentId,
        folder: task.folderId ? folderFromId(task.folderId)?.title ?? '' : '',
        context: task.contextId ? contextFromId(task.contextId)?.title ?? '' : '',
        goal: task.goalId ? goalFromId(task.goalId)?.title ?? '' : '',
        start: task.start?.toISO(),
        due: task.due?.toISO(),
        repeat: task.repeat,
        priority: task.priority,
        status: task.status,
        star: task.isStarred,
        note: task.note,

      }));

      return Papa.unparse(tasksForExport, {
        header: true,
      });
    },
  },
});


