import { Constants } from "@/constants/constants";
import { AllFilter } from "@/models/filters/AllFilter";
import { DateFilterOption } from "@/models/filters/DateFilterOption";
import { DueDateFilter } from "@/models/filters/DueDateFilter";
import { GoalFilter } from "@/models/filters/GoalFilter";
import { IsCompleteFilter } from "@/models/filters/IsCompleteFilter";
import { NotFilter } from "@/models/filters/NotFilter";
import { PriorityFilter } from "@/models/filters/PriorityFilter";
import { StarredFilter } from "@/models/filters/StarredFilter";
import { StartDateFilter } from "@/models/filters/StartDateFilter";
import { TextFilter } from "@/models/filters/TextFilter";
import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import { ContextFilter } from "@/models/filters/ContextFilter";
import { FolderFilter } from "@/models/filters/FolderFilter";
import { FolderNoteFilter } from "@/models/filters/FolderNoteFilter";
import { TextNoteFilter } from "@/models/filters/TextNoteFilter";

// import { currentUser } from "@/firebase/firebase-auth";
import { useContextsStore } from "@/stores/useContextsStore";
import { useFoldersStore } from "@/stores/useFoldersStore";
import { useGoalsStore } from "@/stores/useGoalsStore";
// import { getItem, default as localForage, ready, setItem } from 'localforage';
// import localStorage from 'local-storage'

import { watch } from "vue";
import { useUserStore } from "./useUserStore";

/** Serialise the state of this store, avoiding crashes due to circular references. */
export function serializeState(state): string {
  const copy = { ...state }
  delete copy.component
  delete copy.vnode

  // I only want to serialise booleans and strings.
  // some other contents of state are crashing JSON.stringify

  for (const key in copy) {
    if (typeof copy[key] != 'string' && typeof copy[key] != 'boolean') {
      // console.log(`Not stringifying settings state variable ${key} as its type is ${typeof copy[key]}`)
      copy[key] = undefined
    }

    // else {
    //   console.log(`Stringifying settings state variable ${key} as its type is ${typeof copy[key]}`)
    // }

    // if (typeof copy[key] === 'object' && copy[key] !== null) {
    //   copy[key] = serializeState(copy[key])
    // }
  }

  // console.log(`SerialiseState made a copy`, copy)
  const json = JSON.stringify(copy)
  // console.log(`json stringified the copy to ${json}`, copy)

  return json
}

export const useSettingsStore = defineStore("settings", {

  /** state factory settings */
  state: () => ({

    settingsLoaded: false,
    settingsLoading: false,
    settingsVersion: 2,
    useMYN: false,
    autoUrgency: false,

    groupBy: "Priority",
    groupDirection: "descending",
    sort1By: "Start",
    sort1Direction: "descending",
    sort2By: "Due",
    sort2Direction: "ascending",
    sort3By: "Title",
    sort3Direction: "ascending",

    sortNotesBy: "Title",
    sortNotesDirection: "ascending",

    currentFilterId: '',
    currentNoteFilterId: '',

    tasksSearchText: '',
    notesSearchText: '',
    taskPageFiltersSubtasks: false,

    showFutureStarts: true,
    showCompleted: true,
    newTasksStartToday: true,
    showNegative: true,
    filterSearchResults: false,
    currentTab: "tasks",
    openFilterGroup: "none",
    openNoteFilterGroup: "none",

    /** allows the user to toggle visibility of the task or note filter menu on a wide screen device. 
     * On narrow devices like phones, toggling is done by a menuController instead.
    */
    showFilterMenu: false,
    isImporting: false,



  }),
  // persist: {
  //   key: `taskangel_settings_${currentUser?.uid ?? 'taskangel_no_user'}`,
  // //   key: ()=>{`taskangel_settings_${currentUser?.uid ?? 'taskangel_no_user'}`},
  // },


  getters: {
    /** Get a setting from a setings key.
     * @remarks getSetting takes a parameter so we have to return a function with a parameter.
     * 
     * 
     */
    getSetting() {
      try {
        return (key: string) => {
          if (!this.settingsLoaded && !this.settingsLoading) {
            this.loadSettings()
          }
          switch (key) {
            case Constants.USE_MYN: return this.useMYN
            case Constants.AUTO_URGENCY: return this.autoUrgency
            case Constants.GROUP_TASKS_BY: return this.groupBy
            case Constants.GROUP_TASKS_DIRECTION: return this.groupDirection
            case Constants.SORT_TASKS_FIRST_BY: return this.sort1By
            case Constants.SORT_TASKS_FIRST_DIRECTION: return this.sort1Direction
            case Constants.SORT_TASKS_SECOND_BY: return this.sort2By
            case Constants.SORT_TASKS_SECOND_DIRECTION: return this.sort2Direction
            case Constants.SORT_TASKS_THIRD_BY: return this.sort3By
            case Constants.SORT_TASKS_THIRD_DIRECTION: return this.sort3Direction
            case Constants.CURRENT_TAB: return this.currentTab
            case Constants.NEW_TASKS_START_TODAY: return this.newTasksStartToday

            case Constants.SORT_NOTES_BY: return this.sortNotesBy
            case Constants.SORT_NOTES_DIRECTION: return this.sortNotesDirection

          }
          return null;
        }
      } catch (error) {
        Sentry.captureException(error);
        Sentry.captureMessage(`Failure in useSettings.getSetting`);
      }
    },
    priorityTitle: (state) => {
      const myn = !!state.useMYN;
      return myn ? "Urgency" : "Priority"
    },

    priorityTitleOf: (state) => (priority?: string) => {
      const myn = !!state.useMYN;
      if (!myn) {
        return priority
      }
      const p = priority ?? "Low"
      switch (p) {
        case 'Top': return 'Significant Outcome'
        case 'High': return 'Critical Now'
        case 'Medium': return 'Opportunity Now'
        case 'Low': return 'Over-the-Horizon'
        case 'Negative': return 'Negative Urgency'
        default: return p
      }
    },

    optionsFilter: (state) => {
      const result = new AllFilter("Options Filter")
      const selectedFilterId = state.currentFilterId ?? Constants.ALL_TASKS_FILTER
      if (!state.showCompleted && selectedFilterId != Constants.COMPLETED_FILTER) {
        result.add(new IsCompleteFilter(false))
      }
      if (!state.showNegative && selectedFilterId != Constants.PRIORITY_NEGATIVE_FILTER) {
        result.add(new NotFilter('Not negative', new PriorityFilter(Constants.PRIORITY_NEGATIVE)));
      }

      if (!state.showFutureStarts && !selectedFilterId.includes('start')) {
        const futureFilter = new StartDateFilter(DateFilterOption.Future)
        result.add(new NotFilter('Not future start', futureFilter));
      }
      return result
    },

    currentFilter() {
      const result = this.optionsFilter
      if (this.tasksSearchText && this.tasksSearchText.length > 0) {
        result.add(new TextFilter(this.tasksSearchText))
        return result
      }

      const selectedFilterId = this.currentFilterId.value ?? Constants.ALL_TASKS_FILTER

      if (selectedFilterId) {

        switch (selectedFilterId) {
          case Constants.ALL_TASKS_FILTER:
            // nothing more to add
            return result;
          case Constants.COMPLETED_FILTER:
            result.add(new IsCompleteFilter(true));
            break;
          case Constants.STARRED_FILTER:
            result.add(new StarredFilter(true))
            break;
          case Constants.PRIORITY_TOP_FILTER:
            result.add(new PriorityFilter(Constants.PRIORITY_TOP))
            break;
          case Constants.PRIORITY_HIGH_FILTER:
            result.add(new PriorityFilter(Constants.PRIORITY_HIGH))
            break;
          case Constants.PRIORITY_MEDIUM_FILTER:
            result.add(new PriorityFilter(Constants.PRIORITY_MEDIUM))
            break;
          case Constants.PRIORITY_LOW_FILTER:
            result.add(new PriorityFilter(Constants.PRIORITY_LOW))
            break;
          case Constants.PRIORITY_NEGATIVE_FILTER:
            result.add(new PriorityFilter(Constants.PRIORITY_NEGATIVE))
            break;

          case Constants.URGENCY_SOC_FILTER:
            result.add(new PriorityFilter(Constants.URGENCY_SOC))
            break;
          case Constants.URGENCY_CRITICAL_NOW_FILTER:
            result.add(new PriorityFilter(Constants.URGENCY_CRITICAL_NOW))
            break;
          case Constants.URGENCY_OPPORTUNITY_NOW_FILTER:
            result.add(new PriorityFilter(Constants.URGENCY_OPPORTUNITY_NOW))
            break;
          case Constants.URGENCY_OVER_THE_HORIZON_FILTER:
            result.add(new PriorityFilter(Constants.URGENCY_OVER_THE_HORIZON))
            break;

          case Constants.START_OVERDUE_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Overdue))
            break;
          case Constants.START_TODAY_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Today))
            break;
          case Constants.START_TOMORROW_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Tomorrow))
            break;
          case Constants.START_WEEK_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Week))
            break
          case Constants.START_MONTH_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Month))
            break
          case Constants.START_3_MONTHS_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Quarter))
            break
          case Constants.START_YEAR_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Year))
            break
          case Constants.START_UNDATED_FILTER:
            result.add(new StartDateFilter(DateFilterOption.Undated))
            break;

          case Constants.DUE_OVERDUE_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Overdue))
            break;
          case Constants.DUE_TODAY_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Today))
            break;
          case Constants.DUE_TOMORROW_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Tomorrow))
            break;
          case Constants.DUE_WEEK_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Week))
            break
          case Constants.DUE_MONTH_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Month))
            break
          case Constants.DUE_3_MONTHS_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Quarter))
            break
          case Constants.DUE_YEAR_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Year))
            break
          case Constants.DUE_UNDATED_FILTER:
            result.add(new DueDateFilter(DateFilterOption.Undated))
            break;
          case Constants.GOAL_NONE_FILTER:
            result.add(new GoalFilter())
            break;

          default: // filter id's with value to decode.
            if (selectedFilterId.startsWith(Constants.GOAL_FILTER_PREFIX)) {
              const goalId = selectedFilterId.slice(Constants.GOAL_FILTER_PREFIX.length)
              const goal = useGoalsStore().goalFromId(goalId)
              result.add(new GoalFilter(goal))
            }
            // folders
            if (selectedFilterId.startsWith(Constants.FOLDER_FILTER_PREFIX)) {
              const id = selectedFilterId.slice(Constants.FOLDER_FILTER_PREFIX.length)
              const folder = useFoldersStore().folderFromId(id)
              if (folder) {
                result.add(new FolderFilter(folder))
              }
            }
            // contexts
            if (selectedFilterId.startsWith(Constants.CONTEXT_FILTER_PREFIX)) {
              const id = selectedFilterId.slice(Constants.CONTEXT_FILTER_PREFIX.length)
              const context = useContextsStore().contextFromId(id)
              result.add(new ContextFilter(context))
            }

            break;
        } // switch selected filter id
      }
      return result

    },

    currentFilterDescription() {

      if (!!this.tasksSearchText && this.tasksSearchText.length > 0) {
        return `Search: ${this.tasksSearchText}`
      }

      const selectedFilterId: string = this.currentFilterId ?? Constants.ALL_TASKS_FILTER
      if (!selectedFilterId) {
        return 'All Tasks'
      }

      switch (selectedFilterId) {
        case Constants.ALL_TASKS_FILTER:
          return 'All Tasks'
        case Constants.COMPLETED_FILTER:
          return 'Completed'
        case Constants.STARRED_FILTER:
          return 'Starred'

        case Constants.PRIORITY_TOP_FILTER:
          return Constants.URGENCY_MAP["Top"]
        case Constants.PRIORITY_HIGH_FILTER:
          return Constants.URGENCY_MAP["High"]

        case Constants.PRIORITY_MEDIUM_FILTER:
          return Constants.URGENCY_MAP["Medium"]
        case Constants.PRIORITY_LOW_FILTER:
          return Constants.URGENCY_MAP["Low"]
        case Constants.PRIORITY_NEGATIVE_FILTER:
          return Constants.URGENCY_MAP["Negative"]

        case Constants.URGENCY_SOC_FILTER:
          return "Significant Outcome"
        case Constants.URGENCY_CRITICAL_NOW_FILTER:
          return "Critical Now"
        case Constants.URGENCY_OPPORTUNITY_NOW_FILTER:
          return "Opportunity Now"
        case Constants.URGENCY_OVER_THE_HORIZON_FILTER:
          return "Over-the-Horizon"

        case Constants.START_OVERDUE_FILTER:
          return 'Overdue'
        case Constants.START_TODAY_FILTER:
          return 'Start Today'
        case Constants.START_TOMORROW_FILTER:
          return 'Start Tomorrow'
        case Constants.START_WEEK_FILTER:
          return 'Start Within A Week'
        case Constants.START_MONTH_FILTER:
          return 'Start Within A Month'
        case Constants.START_3_MONTHS_FILTER:
          return 'Start Within 3 Months'
        case Constants.START_YEAR_FILTER:
          return 'Start Within a Year'
        case Constants.START_UNDATED_FILTER:
          return 'No Start Date'

        case Constants.DUE_OVERDUE_FILTER:
          return 'Overdue'
        case Constants.DUE_TODAY_FILTER:
          return 'Due Today'
        case Constants.DUE_TOMORROW_FILTER:
          return 'Due Tomorrow'
        case Constants.DUE_WEEK_FILTER:
          return 'Due Within A Week'
        case Constants.DUE_MONTH_FILTER:
          return 'Due Within A Month'
        case Constants.DUE_3_MONTHS_FILTER:
          return 'Due Within 3 Months'
        case Constants.DUE_YEAR_FILTER:
          return 'Due Within a Year'
        case Constants.DUE_UNDATED_FILTER:
          return 'No Due Date'
        case Constants.GOAL_NONE_FILTER:
          return 'No Goal'

        default: // filter id's with value to decode.
          // goals
          if (selectedFilterId.startsWith(Constants.GOAL_FILTER_PREFIX)) {
            const goalId = selectedFilterId.slice(Constants.GOAL_FILTER_PREFIX.length)
            const goal = useGoalsStore().goalFromId(goalId)
            return `Goal: ${goal?.title ?? "None"}`
          }

          // folders
          if (selectedFilterId.startsWith(Constants.FOLDER_FILTER_PREFIX)) {
            const id = selectedFilterId.slice(Constants.FOLDER_FILTER_PREFIX.length)
            const folder = useFoldersStore().folderFromId(id)
            return `Folder: ${folder?.title ?? Constants.INBOX_TITLE}`
          }

          // contexts
          if (selectedFilterId.startsWith(Constants.CONTEXT_FILTER_PREFIX)) {
            const id = selectedFilterId.slice(Constants.CONTEXT_FILTER_PREFIX.length)
            const context = useContextsStore().contextFromId(id)

            return `Context: ${context?.title ?? "None"}`
          }

          break;
      }

      return "All Tasks"



    },

    currentNoteFilter() {

      if (this.notesSearchText && this.notesSearchText.length > 0) {
        return new TextNoteFilter(this.notesSearchText)
      }

      const result = this.currentNoteFilterId &&
        this.currentNoteFilterId.startsWith(Constants.FOLDER_NOTE_FILTER_PREFIX) &&
        new FolderNoteFilter(this.currentNoteFilterId.slice(Constants.FOLDER_FILTER_PREFIX.length))

      return result;

    },

    currentNoteFilterDescription() {

      if (this.notesSearchText && this.notesSearchText.length > 0) {
        return `Search: ${this.notesSearchText}`
      }

      const selectedNoteFilterId = this.currentNoteFilterId ?? Constants.ALL_NOTES_FILTER

      if (selectedNoteFilterId) {
        if (selectedNoteFilterId.startsWith(Constants.FOLDER_NOTE_FILTER_PREFIX)) {
          const id = selectedNoteFilterId.slice(Constants.FOLDER_FILTER_PREFIX.length)
          const folder = useFoldersStore().folderFromId(id)
          if (folder) {
            return `Folder: ${this.folder.title}`
          }
        }
      }

      return "All Notes"

    }


  },
  actions: {

    clearSettings() {
      // console.log(`SettingsStore is clearing settings`)
      this.state = {}
    },

    async loadSettings() {

      const userStore = useUserStore();

      const userKey = `taskangel_settings_${userStore.user?.uid || 'no_user_id'}`
      // console.log(`loadSettings starts for user key ${userKey}`)



      try {



        const json = localStorage.getItem(userKey)
        if (json) {
          const s = JSON.parse(json)
          s.settingsLoaded = true;
          s.settingsLoading = false;
          // console.log(`loadSettings is patching state:`, s)
          this.$patch(s)
          this.settingsLoading = true;
          // console.log(`loadSettings has patched state with: ${serializeState(s)}, becomes  ${serializeState(this)}}`)
          // Sentry.captureMessage(`loadSettings for user ${userStore.user?.uid || 'no_user_id'} has patched state with: ${serializeState(s)}, becomes  ${serializeState(this)}}`)
        }

      }
      catch (error) {
        // console.log(`loadSettings sees error `, error)
        Sentry.captureException(error);
        // Sentry.captureMessage(`Failure in while watching settings state changes`);
      }
    },

    async init() {
      // console.log(`useSettings init starts loading settings`)
      await this.loadSettings()
      const userStore = useUserStore();
      userStore.$subscribe(async () => {
        // console.log(`useSettings sees userStore mutation and reloads settings`, mutation)
        await this.loadSettings()

      })
      watch(() => this, async (state) => {
        const userKey = `taskangel_settings_${userStore.user?.uid || 'no_user_id'}`
        // console.log(`useSettings watch sees modified state:`, state)
        // we use a replacer to remove items that could cause circular references in stringify
        const stringifiedState = serializeState(state)
        localStorage.setItem(userKey, stringifiedState)
        // console.log(`useSettings watch has saved settings: ${stringifiedState}`)
        // Sentry.captureMessage(`useSettings watch for user ${userStore.user?.uid || 'no_user_id'}  has saved settings: ${stringifiedState}`)
      },
        { deep: true }
      );

    },


    async setupMYN(isOn: boolean): Promise<void> {
      try {
        if (isOn) {
          this.$patch({

            useMYN: true,
            autoUrgency: true,
            groupBy: "Urgency",
            groupDirection: "descending",
            sort1By: "Start",
            sort1Direction: "descending",
            sort2By: "Due",
            sort2Direction: "ascending",
            sort3By: "Title",
            sort3Direction: "ascending",
            showCompleted: false,
            showNegative: false,
            showFutureStarts: false,
          })
        } else {
          this.$patch({

            useMYN: false,
            autoUrgency: false,
            groupBy: (this.groupBy === "Urgency") && "Priority" || this.groupBy,
            sort1By: (this.sort1By === "Urgency") && "Priority" || this.sort1By,
            sort2By: (this.sort2By === "Urgency") && "Priority" || this.sort2By,
            sort3By: (this.sort3By === "Urgency") && "Priority" || this.sort3By,
          })
        }
        // await this.saveSettings();

      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            section: "useSettingsStore",
          },
        });
        // Sentry.captureMessage(`Failure in useSettings.setupMYN`);
      }

    },
  }

}) // define store
