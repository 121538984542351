// set up the model

import { DateHelper } from '@/helpers/DateHelper';
import { DateTime } from 'luxon';
import { Syncable } from './Syncable';

export interface ContextType extends Syncable{
    id?: string;
    title?: string;
    added?: DateTime;
    modified?: DateTime;

    copyToSimpleObject(): any;

}

export class ContextObject implements ContextType {
    id?: string;
    title?: string;
    added?: DateTime;
    modified?: DateTime;

    constructor(title?: string){
        this.title = title ?? '';
    }

    copyToSimpleObject() {
        const defaults = {
            id: '',
            title: '',
            added: '',
            modified: '',
        }

        const result = {
            ...defaults,
            ...this,
        }

        return result;

    }

    copyToFirestoreObject() {
        const result = {
          id: this.id ?? "",
          title: this.title ?? "",
          added: this.added?.toISO() ?? "",
          modified: this.modified?.toISO() ?? "",
        };
        return result
    }

    static copyFromAnyObject(source: any, withId?: string): ContextObject {
        const c = new ContextObject();
        if (withId) { c.id = withId; } else { c.id = source.id; }
        if (source.title) { c.title = source.title; }
           
        if (source.added) {
            c.added = DateHelper.copyDate(source.added);
        }
        if (source.modified) {
            c.modified = DateHelper.copyDate(source.modified);
        }

        return c;
    }
}