import * as Sentry from "@sentry/vue";

const isDebugging = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
export class Logger {

    static log(message: string) {

        if (isDebugging) {
            // eslint-disable-next-line no-console
            console.log(message)
            return;
        }
    }

    static logError(context: string, error?: Error) {

        const errorMessage = `${context}: ${error?.message ?? error}`

        if (isDebugging) {
            // eslint-disable-next-line no-console
            console.error(errorMessage)
            return;
        }

        Sentry.captureException(error, {
            contexts: {
                context: { context }
            }
        });
    }
}