import { Filter } from "./Filter";
import { TaskObject } from "../TaskObject";
import { DateTime } from "luxon";
import { DateFilterOption } from "../filters/DateFilterOption";

/**
 * Filters tasks by start date, using friendly terms like 'overstart', 'today' and 'undated'
 */
export class AddedDateFilter extends Filter {
  public get debugDescription(): string {
    return `Added date filter: ${this.value}`;
  }
  value: DateFilterOption;
  constructor(value: DateFilterOption) {
    // console.log(`Constructing added date filter for date option ${value}`)
    super(value);
    this.value = value;
  }

  /**
   * Returns true if a task matches the value expressed as Today, Yesterday or whatever
   * @param item - the task
   *
   * @remarks
   * The value for comparison is set in the constructor
   */
  public isFiltered(item: TaskObject): boolean {
    let result = false;
    const now = DateTime.local();
    const today = now.startOf("day").toUTC(); // added date is a utc DateTime so we need to allow for that here
    const added = item.added;

    if (!added) {
      // undated
      // console.log(`Added date filter for date option ${this.value} gives result ${this.value === DateFilterOption.Undated} for task with no added date`)
      return this.value === DateFilterOption.Undated;
    }

    switch (this.value) {
      case DateFilterOption.Today:
        result = !!added && added > today && added < today.plus({ days: 1 });
        break;
      case DateFilterOption.Yesterday:
        result = !!added && added > today.minus({ days: 1 }) && added < today;
        break;
      case DateFilterOption.Week:
        result =
          !!added &&
          added < today.plus({ days: 1 }) &&
          added > today.minus({ days: 7 });
        break;
      case DateFilterOption.Month:
        result =
          !!added &&
          added < today.plus({ days: 1 }) &&
          added > today.minus({ months: 1 });
        break;
      case DateFilterOption.Quarter:
        result =
          !!added &&
          added < today.plus({ days: 1 }) &&
          added > today.minus({ months: 3 });
        break;
      case DateFilterOption.Year:
        result =
          !!added &&
          added < today.plus({ days: 1 }) &&
          added > today.minus({ years: 1 });
        break;
      case DateFilterOption.Older:
        result = !!added && added < today.minus({ years: 1 });
        break;

    }

    // console.log(`Added date filter for date option ${this.value} gives result ${result} for task ${item.title} with added date ${added}`)
    return result;
  }

  /**
   * Calculates a description from the comparison value
   *
   * @remarks
   * Quarter is translated to '3 months'
   */
  public get description() {
    switch (this.value) {
      case DateFilterOption.Undated:
        return "no added date";
      case DateFilterOption.Today:
        return "added today";
      case DateFilterOption.Yesterday:
        return "added yesterday";
      case DateFilterOption.Week:
        return "added within a week";
      case DateFilterOption.Month:
        return "added within a month";
      case DateFilterOption.Quarter:
        return "added within 3 months";
      case DateFilterOption.Year:
        return "added within a year";
      case DateFilterOption.Older:
        return "added more than a year ago";
      default:
        return undefined;
    }
  }
}
