
import { Constants } from "@/constants/constants";
import { DateHelper } from "@/helpers/DateHelper";
import { useSettingsStore } from "@/stores/useSettingsStore";
import * as Sentry from "@sentry/vue";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import { Priority } from "./Priority";
import { Status } from "./Status";
import { Syncable } from "./Syncable";
import { Urgency } from "./Urgency";
import { UrgencyUtils } from "./UrgencyUtils";



export interface TaskType extends Syncable {
  id?: string;
  title?: string;
  completed?: DateTime;
  isStarred?: boolean;
  due?: DateTime;
  showDueTime?: boolean;
  repeat?: string;
  start?: DateTime;
  parentId?: string;
  folderId?: string;
  contextId?: string;
  goalId?: string;
  priority?: Priority;
  status?: Status;
  added?: DateTime;
  modified?: DateTime;
  note?: string;
  urgency?: Urgency;
  imported?: DateTime;

  toSimpleObject(): any;
}

export class TaskObject implements TaskType {
  id?: string;
  title?: string;
  completed?: DateTime;
  isStarred?: boolean;
  due?: DateTime;
  showDueTime?: boolean;
  repeat?: string;
  start?: DateTime;
  parentId?: string;
  folderId?: string;
  contextId?: string;
  goalId?: string;
  priority?: Priority;
  status?: Status;
  added?: DateTime;
  modified?: DateTime;
  note?: string;
  type: "Task";
  // isSelected?: boolean;
  imported?: DateTime;

  constructor(title?: string) {
    this.title = title ?? '';
  }

  public get urgency() {
    const settingsStore = useSettingsStore();
    const { autoUrgency } = storeToRefs(settingsStore)
    return UrgencyUtils.urgencyFromPriorityWithAutoUrgency(this.priority, this.due, this.completed, autoUrgency.value)
  }

  public set urgency(urgency: Urgency) {
    this.priority = UrgencyUtils.urgencyToPriority(urgency)
  }


  public getUrgency(autoUrgency: boolean): Urgency {
    return UrgencyUtils.urgencyFromPriorityWithAutoUrgency(this.priority, this.due, this.completed, autoUrgency)
  }


  toSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      completed: "",
      isStarred: false,
      due: "",
      showDueTime: false,
      repeat: Constants.REPEAT_NONE,
      start: "",
      parentId: "",
      folderId: "",
      contextId: "",
      goalId: "",
      priority: Priority.Low,
      status: Status.None,
      added: "",
      modified: "",
      note: "",
      imported: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    return result;
  }

  public static autoUrgency = false






  public calcUrgency(withAutoUrgency: boolean) {

    return UrgencyUtils.urgencyFromPriorityWithAutoUrgency(this.priority, this.due, this.completed, withAutoUrgency)
  }


  toFirestoreObject() {
    try {
      const result = {
        id: this.id ?? "",
        title: this.title ?? "",
        completed: (this.completed as DateTime) && this.completed.toISO() || "",
        isStarred: this.isStarred ?? false,
        due: (this.due as DateTime) && this.due.toISO() || "",
        showDueTime: this.showDueTime ?? false,
        repeat: this.repeat ?? "",
        start: (this.start as DateTime) && this.start.toISO() || "",
        parentId: this.parentId ?? "",
        folderId: this.folderId ?? "",
        contextId: this.contextId ?? "",
        goalId: this.goalId ?? "",
        priority: this.priority ?? "",
        status: "",
        added: (this.added as DateTime) && this.added.toISO() || "",
        modified: (this.modified as DateTime) && this.modified.toISO() || "",
        imported: (this.imported as DateTime) && this.imported.toISO() || "",
        note: this.note ?? "",

      };

      return result;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "TaskObject",
        }
      })
      throw error

    }
  }

  static fromAnyObject(source: any, withId?: string): TaskObject {

    if (!source) {
      return null;
    }

    try {

      const t = new TaskObject();
      for (const key in source) {

        switch (key) {
          case "completed":
          case "start":
          case "due":
          case "added":
          case "modified":
          case "imported":
            t[key] = DateHelper.copyDate(source[key])
            break;
          case "id":
            t.id = withId ?? source.id;
            break;
          default:
            t[key] = source[key];
            break;
        }

      }

      return t;

    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "TaskObject",
        }
      });
    }
  }
}
