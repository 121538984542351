// set up the model

// import { Constants } from '@/constants/constants';
// import { Constants } from "@/constants/constants";
import { DateHelper } from "@/helpers/DateHelper";
import { DateTime } from "luxon";
import { Syncable } from "./Syncable";
// import { Priority } from './Priority';
// import { Status } from "./Status";

export interface NoteType extends Syncable {
  id?: string;
  title?: string;
  folderId?: string;
  added?: DateTime;
  modified?: DateTime;
  text?: string;

  copyToSimpleObject(): any;
}

export class NoteObject implements NoteType {
  id?: string;
  title?: string;
  folderId?: string;
  added?: DateTime;
  modified?: DateTime;
  text?: string;

  copyToSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      folderId: "",
      added: "",
      modified: "",
      text: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    // console.log(`CopyToSimpleObject converts ${JSON.stringify(this)} to this: ${JSON.stringify(result)}`)

    return result;
  }

  copyToFirestoreObject() {
    const result = {
      id: this.id ?? "",
      title: this.title ?? "",
      folderId: this.folderId ?? "",
      added: this.added?.toISO() ?? "",
      modified: this.modified?.toISO() ?? "",
      text: this.text ?? "",
    };

    return result;
  }

  static copyFromAnyObject(source: any, withId?: string): NoteObject {
    const t = new NoteObject();
    if (withId) {
      t.id = withId;
    } else {
      t.id = source.id;
    }
    if (source.title) {
      t.title = source.title;
    }
    
    if (source.folderId) {
      t.folderId = source.folderId;
    }
    
    if (source.added) {
      t.added = DateHelper.copyDate(source.added);
    }
    if (source.modified) {
      t.modified = DateHelper.copyDate(source.modified);
    }
    if (source.text) {
      t.text = source.text;
    }

    // console.log(`CopyFromAnyObject converts ${JSON.stringify(source)} to this: ${JSON.stringify(t)}`)
    return t;
  }
}
