import { DateTime } from "luxon";
import { DateFilterOption } from "../filters/DateFilterOption";
import { TaskObject } from "../TaskObject";
import { Filter } from "./Filter";

/**
 * Filters tasks by due date, using friendly terms like 'overdue', 'today' and 'undated'
 */
export class DueDateFilter extends Filter {
  public get debugDescription(): string {
    return `Due date filter: option ${this.value}`;
  }
  value: DateFilterOption;
  constructor(value: DateFilterOption) {
    super(value);
    this.value = value;
  }

  /**
   * Returns true if a task matches the value expressed as 'Today', Overdue' or whatever
   * @param item - the task
   *
   * @remarks
   * The value for comparison is set in the constructor
   */
  public isFiltered(item: TaskObject): boolean {
    let result = false;
    const now = DateTime.local();
    const today = now.startOf("day");
    const due = item.due;

    if (due == null) {
      // undated
      return this.value === DateFilterOption.Undated;
    }

    switch (this.value) {
      case DateFilterOption.Overdue:
        result = due < today;
        break;
      case DateFilterOption.Today:
        result = due > today && due < today.plus({ days: 1 });
        break;
      case DateFilterOption.ByToday:
        result = due < today.plus({ days: 1 });
        break;
      case DateFilterOption.Tomorrow:
        result = due > today.plus({ days: 1 }) && due < today.plus({ days: 2 });
        break;
      case DateFilterOption.Week:
        result = due > today && due < today.plus({ days: 7 });
        break;
      case DateFilterOption.Month:
        result = due > today && due < today.plus({ months: 1 });
        break;
      case DateFilterOption.Quarter:
        result = due > today && due < today.plus({ months: 3 });
        break;
      case DateFilterOption.Year:
        result = due > today && due < today.plus({ years: 1 });
        break;
    }

    return result;
  }

  /**
   * Calculates a description from the comparison value
   *
   * @remarks
   * Quarter is translated to '3 months'
   */
  public get description() {
    switch (this.value) {
      case DateFilterOption.Undated:
        return "due undated";
      case DateFilterOption.Overdue:
        return "overdue";
      case DateFilterOption.Today:
        return "due today";
      case DateFilterOption.ByToday:
        return "due by today";
      case DateFilterOption.Tomorrow:
        return "due tomorrow";
      case DateFilterOption.Week:
        return "due within a week";
      case DateFilterOption.Month:
        return "due within a month";
      case DateFilterOption.Quarter:
        return "due within 3 months";
      case DateFilterOption.Year:
        return "due within a year";
    }
  }
}
