
import { browserLocalPersistence, browserPopupRedirectResolver, browserSessionPersistence, indexedDBLocalPersistence, initializeAuth, User } from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: "taskangel-f1161.firebaseapp.com",
  databaseURL: "https://taskangel-f1161.firebaseio.com",
  projectId: "taskangel-f1161",
  storageBucket: "taskangel-f1161.appspot.com",
  messagingSenderId: "541410762395",
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: "G-S1VDL12M4V",
};

import { Logger } from "@/helpers/Logger";
import { useUserStore } from "@/stores/useUserStore";
import { initializeApp } from 'firebase/app';
import { collection, doc, DocumentReference, getFirestore } from 'firebase/firestore';
import { reactive, toRefs } from "vue";


export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);

let userRef;

export const auth = initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
  popupRedirectResolver: browserPopupRedirectResolver,
});

export let tasksCollectionRef = null;
export let notesCollectionRef = null;
export let foldersCollectionRef = null;
export let contextsCollectionRef = null;
export let goalsCollectionRef = null;
export let subscriptionsCollectionRef = null;
export let checkoutSessionsCollectionRef = null;

const state = reactive<{
  user?: User;
  initialized: boolean;
  error: any;
}>({
  user: null,
  initialized: false,
  error: null,
});

export default function () {
  const authCheck = () => {
    return new Promise((resolve) => {
      !state.initialized &&
        auth.onAuthStateChanged(async (_user: User) => {

          const userStore = useUserStore();
          const { setUser } = userStore;
          setUser(_user);

          try {
            if (!_user) {
              userRef = null;
              tasksCollectionRef = null;
              notesCollectionRef = null;
              foldersCollectionRef = null;
              contextsCollectionRef = null;
              goalsCollectionRef = null;
              subscriptionsCollectionRef = null;
              checkoutSessionsCollectionRef = null;
              resolve(_user);
            }
            if (_user) {
              try {
                userRef = doc(db, "users", _user.uid);

                if (userRef instanceof DocumentReference) {

                  tasksCollectionRef = collection(userRef, "tasks");
                  notesCollectionRef = collection(userRef, "notes");
                  foldersCollectionRef = collection(userRef, "folders");
                  contextsCollectionRef = collection(userRef, "contexts");
                  goalsCollectionRef = collection(userRef, "goals");
                  subscriptionsCollectionRef = collection(userRef, "subscriptions");
                  checkoutSessionsCollectionRef = collection(userRef, "checkout_sessions");
                  Logger.log(`Firebase authCheck sees subscriptionsCollectionRef is ${JSON.stringify(subscriptionsCollectionRef)}`)

                  state.user = _user;
                  setUser(_user);
                }

              } catch (error) {
                Logger.logError("Error creating userRef or tasksRef", error);
              }
            }
            state.initialized = true;
            resolve(_user);
          } catch (error) {
            Logger.logError('error in authCheck', error)
            throw (error)
          }
        });
    });
  };

  return {
    ...toRefs(state),
    db,
    authCheck,
  };
}
