// set up the model

import { DateHelper } from '@/helpers/DateHelper';
import { DateTime } from 'luxon';
import { Syncable } from './Syncable';

export interface FolderType extends Syncable{
    id?: string;
    title?: string;
    added?: DateTime;
    modified?: DateTime;
    note?: string;

    copyToSimpleObject(): any;

}

export class FolderObject implements FolderType {
    id?: string;
    title?: string;
    added?: DateTime;
    modified?: DateTime;
    note?: string;

    constructor(title?: string){
        this.title = title ?? '';
    }

    public copyToSimpleObject() {
        const defaults = {
            id: '',
            title: '',
            added: '',
            modified: '',
            note: '',
        }

        const result = {
            ...defaults,
            ...this,
        }

        return result;

    }

    public copyToFirestoreObject() {
        const result = {
          id: this.id ?? "",
          title: this.title ?? "",
          added: this.added?.toISO() ?? "",
          modified: this.modified?.toISO() ?? "",
          note: this.note ?? "",
        };
        return result
    }

    static copyFromAnyObject(source: any, withId?: string): FolderObject {
        const t = new FolderObject(source.title);
        if (withId) { t.id = withId; } else { t.id = source.id; }
        if (source.title) { t.title = source.title; }
           
        if (source.added) {
            t.added = DateHelper.copyDate(source.added);
        }
        if (source.modified) {
            t.modified = DateHelper.copyDate(source.modified);
        }
        if (source.note) { t.note = source.note; }

        return t;
    }
}