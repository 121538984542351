import { Constants } from "@/constants/constants";
import { db, foldersCollectionRef } from "@/firebase/firebase-auth";
import { FolderObject } from "@/models/FolderModel";
import { MyNotification } from "@/models/Notification";
import { useNotificationsStore } from "@/stores/useNotifications";
import { collection, doc, DocumentData, onSnapshot, QueryDocumentSnapshot, QuerySnapshot, setDoc, writeBatch } from 'firebase/firestore';
import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { v4 as generateUUID } from 'uuid';

// import { useUserStore } from "./useUserStore";
import { Logger } from "@/helpers/Logger";
import { Sorter } from "@/helpers/Sorter";
import { useUserStore } from "./useUserStore";

let firebaseFoldersUnsubscribe: { (): void; (): void } | null = null;



/** Maintain folder list in Pinia store
 * @remarks We are going to keep the folder list sorted alphetically
 */
export const useFoldersStore = defineStore("foldersStore", {
  state: () => ({
    folders: Array<FolderObject>(),
    haveFoldersLoaded: false,
    mostRecentId: "",
    isFirestoreSubscribed: false,
  }),

  /** Getters should be fat arrow functions unless they need to call other getters. That requires the use of this, which is only available in non-arrow functions.
   *
   */
  getters: {
    getFolders: (state) => {
      const result = state.folders ?? [];
      return result;
    },
    allFolders: (state) => {
      const f = state.folders ?? [];
      //   Sorter.sortGoalsInPlace(f);
      return f;
    },


    folderFromId:
      (state) =>
        (id: string): FolderObject | undefined => {
          if (state.folders?.length > 0) {
            for (let i = 0; i < state.folders.length; i += 1) {
              if (state.folders[i].id === id) {
                return state.folders[i];
              }
            }
          }
          return undefined;
        },
    folderFromTitle:
      (state) =>
        (title: string): FolderObject | undefined => {
          const candidates =
            state.folders && state.folders.filter((f) => f.title === title);
          return (candidates && candidates[0]) || undefined;
        },

    allFoldersCount: (state) => {
      return state.folders?.length || 0;
    },
  },
  actions: {
    subscribeToFirestoreFolders() {
      try {

        Logger.log(`subscribeToFirestoreFolders starts`)

        const userStore = useUserStore();
        const user = userStore.user;

        Logger.log(`subscribeToFirestoreFolders sees user is ${JSON.stringify(user)}`)
        Logger.log(`subscribeToFirestoreFolders sees foldersCollectionRef is ${JSON.stringify(foldersCollectionRef)}`)

        firebaseFoldersUnsubscribe = onSnapshot(foldersCollectionRef, async (snap: QuerySnapshot) => {
          try {

            if (this.haveFoldersLoaded) {
              Logger.log(`loadFolders snapshot sees folders have loaded so looks at changes`)
              snap.docChanges().forEach((change) => {
                this.processFirestoreChange(change)
              })

              Logger.log(`folders store is sorting folders after getting changes`)
              this.folders = Sorter.copyAndSortFolders(this.folders)
              return;
            }


            Logger.log(`loadFolders snapshot sees folders have not loaded so loads everything`)
            const foldersForSort = this.convertFirestoreDocs(snap.docs);
            Logger.log(`folders store is sorting folders after getting all folders`)
            this.folders = Sorter.copyAndSortFolders(foldersForSort)

            Logger.log(`loadFolders snapshot has loaded and sorted everything, ${JSON.stringify(this.folders)}`)
            this.haveFoldersLoaded = true;
            this.isLoadingFolders = false;
            return true;
          } catch (error) {
            Logger.logError('Error in loadFolders', error)
            throw error;
          }

        });

        this.isFirestoreSubscribed = true;
      } catch (error) {
        Logger.logError(`subscribeToFirestoreFolders gets error while subscribing`, error);
        throw error;
        // console.error(`subscribeToFirestoreFolders gets error while subscribing: ${error.message ?? error}`)
        // Sentry.captureException(error),
        // {
        //   tags: {
        //     section: "subscribeToFirestoreFolders",
        //   },
        // };
      }

    },
    processFirestoreChange(change) {
      const data = change.doc.data();
      const id = change.doc.id;
      const folder = FolderObject.copyFromAnyObject(data, id);
      if (change.type === "added") this.processFirestoreAdded(folder)
      else if (change.type === "modified") this.processFirestoreModified(folder);
      else if (change.type === "removed") this.processFirestoreRemoved(folder)
    },

    processFirestoreAdded(folder: FolderObject) {
      const exists = !!this.folderFromId(folder.id);
      if (!exists) this.folders.push(folder);
    },

    processFirestoreModified(folder: FolderObject) {
      folder.modified = DateTime.utc();
      const folderIndex = this.folders.findIndex(
        (t: FolderObject) => t.id == folder.id
      );
      if (folderIndex < 1) return;

      this.folders.splice(folderIndex, 1);
      this.folders.push(folder);
    },

    processFirestoreRemoved(folder: FolderObject) {
      const folderIndex = this.folders.findIndex(
        (t: FolderObject) => t.id == folder.id
      );
      if (folderIndex < 1) {
        return;
      }
      this.folders.splice(folderIndex, 1);
    },

    convertFirestoreDocs(docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]) {
      const result = Array<FolderObject>();
      docs.forEach((doc: any) => {
        const folder = this.convertFirestoreDoc(doc)
        result.push(folder);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const data = doc.data();
      const id = doc.id;
      return FolderObject.copyFromAnyObject(data, id);
    },


    // subscribeToFirestoreFolders() {
    //   try {

    //     const userStore = useUserStore();
    //     const userDocRef = userStore.userDocRef;
    //     const collectionRef = collection(userDocRef, "folders")

    //     firebaseFoldersUnsubscribe = onSnapshot(collectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
    //       try {

    //         const isListeningForChanges = this.haveFoldersLoaded;

    //         if (isListeningForChanges) {

    //           snapshot.docChanges().forEach((change) => {
    //             const data = change.doc.data();
    //             const id = change.doc.id;
    //             const folder = FolderObject.copyFromAnyObject(
    //               data,
    //               id
    //             );

    //             if (change.type === "added") {
    //               if (!folder.title || !folder.id) return;
    //               if (this.folderWithIdExistsId) return;
    //               this.addFolderToState(folder);
    //             }
    //             if (change.type === "modified") {
    //               if (!folder.title || !folder.id) return;
    //               folder.modified = DateTime.utc();
    //               this.removeFolderWithIdFromState(folder.id)
    //               this.addFolderToState(folder);
    //             }
    //             if (change.type === "removed") {
    //               this.removeFolderWithIdFromState(folder.id)
    //             }
    //           });

    //           Sorter.sortGoalsInPlace(this.folders);

    //           return true;
    //         }

    //         const isLoadingEverything = !this.haveFoldersLoaded;
    //         if (isLoadingEverything) {
    //           snapshot.docs.forEach((doc) => {
    //             const data = doc.data();
    //             const id = doc.id;
    //             const folder = FolderObject.copyFromAnyObject(data, id);
    //             if (!!folder.id && !!folder.title)
    //               this.addFolderToState(folder);
    //           })

    //           Sorter.sortGoalsInPlace(this.folders)
    //         }

    //         const result = snapshot.docs.map((doc) => {

    //           const data = doc.data();
    //           const id = doc.id;
    //           const folder = FolderObject.copyFromAnyObject(data, id);
    //           return folder;
    //         });

    //         if (!result) {
    //           this.folders = [];
    //         } else {
    //           const withTitles = result.filter((c) => !!c.title);
    //           Sorter.sortGoalsInPlace(withTitles);
    //           this.folders = withTitles;
    //         }

    //         this.haveFoldersLoaded = true;
    //         return true;

    //       } catch (error) {
    //         console.error(`subscribeToFirestoreFolders error ${error.message ?? error}`)
    //         Sentry.captureException(error, {
    //           tags: {
    //             section: "subscribeToFirestoreFolders",
    //           },
    //         });
    //       }

    //     });

    //     this.isFirestoreSubscribed = true;
    //   } catch (error) {
    //     Sentry.captureException(error),
    //     {
    //       tags: {
    //         section: "subscribeToFirestoreFolders",
    //       },
    //     };
    //   }

    // },
    loadFolders() {

      if (this.haveFoldersLoaded) return;
      const userStore = useUserStore();
      if (!userStore.user) return;
      if (this.firebaseFoldersUnsubscribe) return;
      this.subscribeToFirestoreFolders();

    },
    unsubscribeFolders() {
      if (firebaseFoldersUnsubscribe) {
        // console.log('pinia unsubscribing folders')
        firebaseFoldersUnsubscribe();
        this.haveFoldersLoaded = false;
      }
    },
    clearFolders() {
      this.folders = [];
      this.haveFoldersLoaded = false;
    },

    // sortFoldersInPlace: (state): FolderObject[] => {
    //   if (state.folders.length === 0) return;
    //   state.folders = Sorter.copyAndSortFolders(state.folders)
    //   Logger.log(`sortedFolders are ${JSON.stringify(state.folders)}`)
    //   return state.folders;
    // },



    addFolderToStateAndSort(folder: FolderObject) {
      const exists = !!this.folderFromId(folder.id)
      if (exists) return;
      this.folders.push(folder)
      this.folders = Sorter.copyAndSortFolders(this.folders);
    },

    addFolderToState(folder: FolderObject) {
      const exists = !!this.folderFromId(folder.id)
      if (exists) return;
      this.folders.push(folder)
    },


    async addFolder(folder: FolderObject) {
      if (!folder.id) folder.id = generateUUID()
      this.addFolderToState(folder);
      this.writeFolderToFirestore(folder)
    },

    async writeFolderToFirestore(folder: FolderObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "folders")
      const docRef = doc(collectionRef, folder.id)
      const obj = folder.copyToFirestoreObject();
      await setDoc(docRef, obj)
    },

    async editFolder(folder: FolderObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;
        const userDocRef = doc(db, "users", userStore.user.uid);

        if (!userDocRef) {
          Logger.log(
            "useFirestore can't find user data"
          );
          Logger.log(Constants.ERROR_NOT_LOGGED_IN);
          return false;
        }

        if (folder.id == null) {
          Logger.log(`Unable to edit folder as it has no id`)
          return;
        }


        folder.modified = DateTime.utc();
        Logger.log(
          `useFirestore is editing folder ${JSON.stringify(
            folder
          )}`
        );

        const folderIndex: number = this.folders.findIndex(
          (t: FolderObject) => t.id == folder.id
        );

        if (folderIndex < 0) {
          return;
        }

        this.removeFolderFromStateAtIndex(folderIndex)
        this.folders.push(folder);

        await this.writeFolderToFirestore(folder);

      } catch (error: any) {
        const notification = MyNotification.fromError(
          error,
          "in editFolder while writing to fs"
        );
        const notifications = useNotificationsStore();
        notifications.addNotification(notification);
      }
    },

    makeDefaultFolder() {
      const folder = new FolderObject();
      return folder;
    },

    removeFolderFromStateAtIndex(index: number) {
      this.folders.splice(index, 1);
    },

    removeFolderWithIdFromState(id: string) {
      const foundIndex = this.folders.findIndex(folder => { folder.id === id });
      if (foundIndex >= 0) this.removeFolderFromStateAtIndex(foundIndex)
    },

    async removeFolder(id: string) {
      await this.removeFolders([id]);
    },

    async removeFolders(ids: string[]) {
      if (!ids) return;
      const userStore = useUserStore();
      for (const id of ids) {

        const folderIndex: number = this.folders.findIndex(
          (t: FolderObject) => t.id == id
        );
        this.removeFolderFromStateAtIndex(folderIndex)
      }
      try {

        let batch = writeBatch(db);
        let itemsInBatch = 0;

        for (const id of ids) {

          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "folders")
          const docRef = doc(collectionRef, id)

          batch.delete(docRef);
        }

        await batch.commit();

      } catch (error: any) {
        Logger.logError(`Error in removeFolders`, error);
        throw error;
        // Sentry.captureException(error);
        // Sentry.captureMessage(`Failure in UseFoldersStore.removeFolders`);
        // const notification = MyNotification.fromError(
        //   error,
        //   "when removing folders"
        // );
        // const notifications = useNotificationsStore();
        // notifications.addNotification(notification);
      }
    },

    /** Unsubscribe from firestore.
     *
     * We call this when App is unmounted
     */
    unsubscribeFirestore() {
      if (this.firebaseFoldersUnsubscribe) {
        firebaseFoldersUnsubscribe();
        this.isFirestoreSubscribed = false;
      }
    },
  }, // actions
}); // define store
