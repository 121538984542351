import { DateTime } from "luxon";
import { DateFilterOption } from "../filters/DateFilterOption";
import { TaskObject } from "../TaskObject";
import { Filter } from "./Filter";

/**
 * Filters tasks by start date, using friendly terms like 'overstart', 'today' and 'undated'
 */
export class StartDateFilter extends Filter {
  public get debugDescription(): string {
    return `Start date filter: ${this.value}`;
  }
  value: DateFilterOption;
  constructor(value: DateFilterOption) {
    super(value);
    this.value = value;
  }

  /**
   * Returns true if a task matches the value expressed as 'Today', Overdue' or whatever
   * @param item - the task
   *
   * @remarks
   * The value for comparison is set in the constructor
   */
  public isFiltered(item: TaskObject): boolean {
    let result = false;
    const now = DateTime.local();
    const today = now.startOf("day");
    const start = item.start;

    if (start == null) {
      // undated
      return this.value === DateFilterOption.Undated;
    }

    switch (this.value) {
      case DateFilterOption.Overdue:
        result = start < today;
        break;
      case DateFilterOption.Today:
        result = start > today && start < today.plus({ days: 1 });
        break;
      case DateFilterOption.ByToday:
        result = start < today.plus({ days: 1 });
        break;
      case DateFilterOption.Tomorrow:
        result =
          start > today.plus({ days: 1 }) && start < today.plus({ days: 2 });
        break;
      case DateFilterOption.Week:
        result = start > today && start < today.plus({ days: 7 });
        break;
      case DateFilterOption.Month:
        result = start > today && start < today.plus({ months: 1 });
        break;
      case DateFilterOption.Quarter:
        result = start > today && start < today.plus({ months: 3 });
        break;
      case DateFilterOption.Year:
        result = start > today && start < today.plus({ years: 1 });
        break;
      case DateFilterOption.Future:
        result = start > today.plus({ days: 1 });
        break;
      case DateFilterOption.Started:
        result = start < today.plus({ days: 1 });
        break;
    }

    return result;
  }

  /**
   * Calculates a description from the comparison value
   *
   * @remarks
   * Quarter is translated to '3 months'
   */
  public get description() {
    switch (this.value) {
      case DateFilterOption.Undated:
        return "start undated";
      case DateFilterOption.Overdue:
        return "start overdue";
      case DateFilterOption.Today:
        return "start today";
      case DateFilterOption.ByToday:
        return "start by today";
      case DateFilterOption.Tomorrow:
        return "start tomorrow";
      case DateFilterOption.Week:
        return "start within a week";
      case DateFilterOption.Month:
        return "start within a month";
      case DateFilterOption.Quarter:
        return "start within 3 months";
      case DateFilterOption.Year:
        return "start within a year";
      default:
        return undefined;
    }
  }
}
