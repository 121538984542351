
import {
  addCircleOutline,
  ban,
  checkmarkCircle,
  filterOutline,
  link,
} from "ionicons/icons";
import { defineComponent, toRefs, ref, computed, SetupContext } from "vue";
import { IonIcon, IonItem, IonLabel, alertController, IonButton, } from "@ionic/vue";
import FilterMenuGroup from "../components/FilterMenuGroup.vue";
import { MenuItemObject } from "../../models/MenuItemObject";
import { useTasksStore } from "@/stores/useTasksStore";
import { useFeatureFlagsStore } from "@/stores/useFeatureFlagsStore";
import { storeToRefs } from "pinia";
import { Constants } from "@/constants/constants";
// import { useStore } from "vuex";
// import { useFiltersStore } from "@/stores/useFilters";

export default defineComponent({
  name: "FilterMenuItem",
  components: {
    IonIcon,
    IonButton,
    IonItem,
    IonLabel,
    FilterMenuGroup,
  },
  props: {
    icon: String,
    title: String,
    filterId: String, // like 'folder.12345' or 'priority.high'
    status: String,
    itemData: MenuItemObject,
    multipleChoice: Boolean,
    count: Number,
    isForNotes: Boolean,
  },
  emits: ["changed", "selected"],
  setup(props, ctx: SetupContext) {
    // console.log(`FilterMenuItem sees props: ${JSON.stringify(props)}`);
    const { filterId, title, status, itemData, isForNotes } = toRefs(props);

    const firestore = useTasksStore();

    const { countTasksFilteredBy } = firestore;

    const featureFlags = useFeatureFlagsStore();
    const { filterLinkFlag } = storeToRefs(featureFlags);

    // const store = useStore();

    // declare properties

    const myFilterId = filterId?.value || "no filterId";
    const myTitle = title?.value || "no title";
    const myItemData = itemData?.value;
    const myCount = computed<number>(() => {
      if (isForNotes.value) {
        // console.log(
        //   `FilterMenuItem with title ${myTitle} for notes has props itemData.count ${itemData.value.count}`
        // );
        return itemData.value.count;
        // const filters = useFiltersStore();
        // const filter = filters.noteFilterFromId(myFilterId);
        // if(!filter) {

        //   const result =  store.getters.notes.length;
        //   console.log(`FilterMenuItem, has no filter so myCount returns all notes count: ${result}`)
        //   return result;
        // }
        // const filteredNotes = store.getters.notes.filter((n) => filter.isFiltered(n));
        // return filteredNotes?.length ?? 0;
      }
      const result = countTasksFilteredBy(filterId.value);
      // console.log(
      //   `FilterMenuItem with title ${myTitle} for tasks has props count ${count.value} and returns ${result}`
      // );
      return result;
    });

    const myStatus = ref("inactive");
    if (status?.value) {
      myStatus.value = status.value;
    }

    // declare computed properties

    // myStatus.value = store.getters.getSetting(myFilterId) ?? "inactive";

    // console.log(`FilterMenuItem status: ${myStatus.value}`);

    const isGroup = computed<boolean>(() => {
      // console.log(`FilterMenuItem myFilterId: ${filterId?.value}`);
      if (filterId?.value) {
        const result = filterId.value.indexOf("group") >= 0;
        // console.log(`FilterMenuItem ${filterId.value} isGroup = ${result}`);
        return result;
      } else {
        // console.log(`FilterMenuItem is not a Group`);
        return false;
      }
    });

    const isInactive = computed<boolean>(() => {
      return myStatus.value === "inactive";
    });
    const isSelected = computed<boolean>(() => {
      return myStatus.value === "selected";
    });
    const isBlocked = computed<boolean>(() => {
      return myStatus.value === "blocked";
    });

    const useFilterLink = computed(() => {
      const result = filterLinkFlag.value && !!navigator.clipboard && !isForNotes.value;
      // console.log(`FilterMenuItem filterLinkFlag is ${result}`);
      return result;
    });

    const titleWithCount = computed(() => {
      const result = `${myTitle} (${myCount.value ?? 0})`;
      // console.log(`FilterMenuItem titleWithCount is ${result}`);
      return result;
    });

    // declare functions

    /** When user clicks on status, we step the status between inactive, selected and blocked
     * and then emit a 'changed' event to the parent
     *
     */
    const onStatusClicked = () => {
      // console.log(
      //   `FilterMenuItem: user clicked status when it was ${myStatus.value}`
      // );
      let newStatus = "selected";

      switch (myStatus.value) {
        case "selected":
          newStatus = "blocked";
          break;
        case "blocked":
          newStatus = "inactive";
          break;
        default:
          newStatus = "selected";
          break;
      }

      // console.log(
      //   `FilterMenuItem emits a changed event with: ${filterId?.value}: ${newStatus}`
      // );
      myStatus.value = newStatus;
      if (filterId?.value) {
        // store.dispatch("setSetting", { key: filterId.value, value: newStatus });
        ctx.emit("changed", { id: filterId.value, value: newStatus });
      }
    };

    /**
     * User has clicked the filter title
     *
     * @remarks
     * In a multiple choice filter panel, tapping the filter is like clicking status
     *
     * In a single choice filter panel, tapping the filter sets the filter to that filter. This is done by the parent when we emit a 'selected' event
     *
     */
    const onClicked = () => {
      if (props.multipleChoice) {
        onStatusClicked();
        return;
      }
      if (filterId?.value) {
        //   console.log(
        //   `FilterMenuItem emits a selected event with: ${filterId?.value}`
        // );
        ctx.emit("selected", { id: filterId.value });
      }
    };

    const onLinkClicked = async () => {
      try {
        if (filterId?.value) {
          // console.log(
          //   `FilterMenuItem sees a link clicked with: ${filterId?.value}`
          // );
          if (!navigator.clipboard) {
            // console.log(
            //   `not using link button because we can't use the clipboard`
            // );
            return;
          }
          const shortcut = `${Constants.APP_URL}/tasks/filter/${filterId.value}`;
          navigator.clipboard.writeText(shortcut);

          // after user has clicked the link, we want something to indicate to the user that this has happened
          const alertMessage =
            `A link to ${myTitle} has been copied to the clipboard`;
          // console.log(`Telling user a link to ${myTitle} has been copied to ${shortcut})`);
          const alert = await alertController.create({
            header: "Link copied",
            message: alertMessage,
            buttons: [
              {
                text: "OK",
              },
            ],
          });
          return alert.present();

          // ctx.emit("selected", { id: filterId.value });
        }
      } catch (err) {
        const alertMessage = `Unable to copy this link because ${
          (err as string) ?? err.message ?? "an error occurred"
        }`;
        // console.log(`Telling user a link copy has failed)`);
        const alert = await alertController.create({
          header: "Error",
          message: alertMessage,
          buttons: [
            {
              text: "Cancel",
            },
          ],
        });
        return alert.present();
      }
    };

    return {
      // properties
      // icon,
      // title,
      // status,
      // filterId,

      myItemData,
      isSelected,
      isInactive,
      isBlocked,
      myTitle,
      myFilterId,
      myStatus,
      isGroup,
      myCount,
      titleWithCount,

      // status,

      // icons
      addCircleOutline,
      ban,
      checkmarkCircle,
      filterOutline,
      link,

      // feature flags

      filterLinkFlag,
      useFilterLink,

      // functions

      onClicked,
      onStatusClicked,
      onLinkClicked,
    };
  },
});
