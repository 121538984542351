import { Constants } from "@/constants/constants";
import { db, goalsCollectionRef } from "@/firebase/firebase-auth";
import { GoalObject } from "@/models/GoalModel";
import { collection, doc, DocumentData, onSnapshot, QueryDocumentSnapshot, QuerySnapshot, setDoc, writeBatch } from 'firebase/firestore';
import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { v4 as generateUUID } from 'uuid';

import { Logger } from "@/helpers/Logger";
import { Sorter } from "@/helpers/Sorter";
import { useUserStore } from "./useUserStore";

let firebaseGoalsUnsubscribe: { (): void; (): void } | null = null;



export const useGoalsStore = defineStore("goalsStore", {

  state: () => ({
    goals: Array<GoalObject>(),
    haveGoalsLoaded: false,
    mostRecentId: "",
    isFirestoreSubscribed: false,
  }),



  /** Getters should be fat arrow functions unless they need to call other getters. That requires the use of this, which is only available in non-arrow functions.
   *
   */
  getters: {
    getGoals: (state) => {
      return state.goals ?? [];
    },
    goalFromId:
      (state) =>
        (id: string): GoalObject | undefined => {
          if (state.goals?.length > 0) {
            for (let i = 0; i < state.goals.length; i += 1) {
              if (state.goals[i].id === id) {
                return state.goals[i];
              }
            }
          }
          return undefined;
        },
    goalFromTitle:
      (state) =>
        (title: string): GoalObject | undefined => {
          const candidates =
            state.goals && state.goals.filter((f) => f.title === title);
          return (candidates && candidates[0]) || undefined;
        },

    allGoals: (state) => {
      return state.goals ?? [];
    },

    allGoalsCount: (state) => {
      return state.goals?.length || 0;
    },
  },
  actions: {
    subscribeToFirestoreGoals() {
      try {

        firebaseGoalsUnsubscribe = onSnapshot(goalsCollectionRef, async (snap: QuerySnapshot) => {
          try {

            if (this.haveGoalsLoaded) {
              snap.docChanges().forEach((change) => {
                this.processFirestoreChange(change)
              })
              this.goals = Sorter.copyAndSortGoals(this.goals)
              return;
            }

            const converted = this.convertFirestoreDocs(snap.docs);
            this.goals = Sorter.copyAndSortGoals(converted);

            this.haveGoalsLoaded = true;
            this.isLoadingGoals = false;
            return true;
          } catch (error) {
            Logger.logError('Error in subscribeToFirestoreGoals', error)
            throw error;
          }

        });

        this.isFirestoreSubscribed = true;
      } catch (error) {
        Logger.logError(`subscribeToFirestoreGoals gets error while subscribing`, error)
        throw error
      }

    },
    processFirestoreChange(change) {
      const data = change.doc.data();
      const id = change.doc.id;
      const goal = GoalObject.copyFromAnyObject(data, id);
      if (change.type === "added") this.processFirestoreAdded(goal)
      else if (change.type === "modified") this.processFirestoreModified(goal);
      else if (change.type === "removed") this.processFirestoreRemoved(goal)
    },

    processFirestoreAdded(goal: GoalObject) {
      const exists = !!this.goalFromId(goal.id);
      if (!exists) this.goals.push(goal);
    },

    processFirestoreModified(goal: GoalObject) {
      goal.modified = DateTime.utc();
      const goalIndex = this.goals.findIndex(
        (t: GoalObject) => t.id == goal.id
      );
      if (goalIndex < 1) return;

      this.goals.splice(goalIndex, 1);
      this.goals.push(goal);
    },

    processFirestoreRemoved(goal: GoalObject) {
      const goalIndex = this.goals.findIndex(
        (t: GoalObject) => t.id == goal.id
      );
      if (goalIndex < 1) {
        return;
      }
      this.goals.splice(goalIndex, 1);
    },

    convertFirestoreDocs(docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]) {
      const result = Array<GoalObject>();
      docs.forEach((doc: any) => {
        const goal = this.convertFirestoreDoc(doc)
        result.push(goal);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const data = doc.data();
      const id = doc.id;
      return GoalObject.copyFromAnyObject(data, id);
    },

    loadGoals() {

      if (this.haveGoalsLoaded) return;
      const userStore = useUserStore();
      if (!userStore.user) return;
      if (this.firebaseGoalsUnsubscribe) return;
      this.subscribeToFirestoreGoals();

    },

    unsubscribeGoals() {
      if (firebaseGoalsUnsubscribe) {
        firebaseGoalsUnsubscribe();
        this.haveGoalsLoaded = false;
      }
    },
    clearGoals() {
      this.goals = [];
      this.haveGoalsLoaded = false;
    }, // clearGoals


    addGoalToStateAndSort(goal: GoalObject) {
      const exists = !!this.goalFromId(goal.id)
      if (exists) return;
      this.goal.push(goal)
      Sorter.sortGoalsInPlace(this.goals);
    },

    addGoalToState(goal: GoalObject) {
      const exists = !!this.goalFromId(goal.id)
      if (exists) return;
      this.goals.push(goal)
    },


    async addGoal(goal: GoalObject) {
      if (!goal.id) goal.id = generateUUID()
      this.addGoalToState(goal);
      this.writeGoalToFirestore(goal)
    },

    async writeGoalToFirestore(goal: GoalObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "goals")
      const docRef = doc(collectionRef, goal.id)
      const obj = goal.copyToFirestoreObject();
      await setDoc(docRef, obj)
    },

    async editGoal(goal: GoalObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;

        goal.modified = DateTime.utc();

        const goalIndex: number = this.goals.findIndex(
          (t: GoalObject) => t.id == goal.id
        );

        if (goalIndex < 0) {
          return;
        }

        this.removeGoalFromStateAtIndex(goalIndex)
        this.goals.push(goal);

        await this.writeGoalToFirestore(goal);

      } catch (error: any) {
        Logger.logError('Error in `editGoal', error)
        throw error
      }
    },

    makeDefaultGoal() {
      const goal = new GoalObject();
      return goal;
    },

    removeGoalFromStateAtIndex(index: number) {
      this.goals.splice(index, 1);
    },

    removeGoalWithIdFromState(id: string) {
      const foundIndex = this.goals.findIndex(goal => { goal.id === id });
      if (foundIndex >= 0) this.removeGoalFromStateAtIndex(foundIndex)
    },

    async removeGoal(id: string) {
      await this.removeGoals([id]);
    },

    async removeGoals(ids: string[]) {
      if (!ids) return;
      const userStore = useUserStore();
      for (const id of ids) {

        const goalIndex: number = this.goals.findIndex(
          (t: GoalObject) => t.id == id
        );
        this.removeGoalFromStateAtIndex(goalIndex)
      }
      try {

        let batch = writeBatch(db);
        let itemsInBatch = 0;

        for (const id of ids) {

          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "goals")
          const docRef = doc(collectionRef, id)

          batch.delete(docRef);
        }

        await batch.commit();

      } catch (error: any) {
        Logger.logError('Error in removeGoals', error);
        throw error;
      }
    },

    unsubscribeFirestore() {
      if (this.firebaseGoalsUnsubscribe) {
        firebaseGoalsUnsubscribe();
        this.isFirestoreSubscribed = false;
      }
    },
  },
});
