import {Constants} from "@/constants/constants";
import {auth, db} from "@/firebase/firebase-auth";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes,} from "firebase/storage";

import {Logger} from "@/helpers/Logger";
import {MyNotification} from "@/models/Notification";
import {useSettingsStore} from "@/stores/useSettingsStore";
import * as Sentry from "@sentry/vue";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from "firebase/auth";
import {doc} from "firebase/firestore";
import {DateTime} from "luxon";
import {ResultAsync} from "neverthrow";
import {defineStore, storeToRefs} from "pinia";
import {useContextsStore} from "./useContextsStore";
import {useFeatureFlagsStore} from "./useFeatureFlagsStore";
import {useFoldersStore} from "./useFoldersStore";
import {useGoalsStore} from "./useGoalsStore";
import {useNotesStore} from "./useNotesStore";
import {useNotificationsStore} from "./useNotifications";
import {useSubscriptionsStore} from "./useSubscriptionsStore";
import {useTasksStore} from "./useTasksStore";


function signOut(): ResultAsync<void, Error> {
  return ResultAsync.fromPromise(auth.signOut(), (originalError: any) => ({
    originalError,
    message: originalError.message,
    name: originalError.code,
  }));
}

export const useUserStore = defineStore("userStore", {
  state: () => ({

    name: "",
    // email: '',
    photoURL: "",
    hasProfileLoaded: false,
    userLoggedOutToUnregister: false,
    authChangeUnsubscribe: null,
    signUpStep: Constants.SIGNUP_NONE,
    signUpUser: "",
    signUpPassword: "",
    _user: null,
    deferredInstallationPrompt: null, // set by App.vue for the 'Install' button to use.
  }),
  getters: {
    user: (state) => {
      return state._user;
    },
    isLoggedIn(state): boolean {
      return !!state._user?.uid;
    },
    uid(state): string {
      return state._user?.uid;
    },

    userDocRef: (state) => {
      return doc(db, "users", state._user.uid);
    },

    email: (state): string => state._user?.email,

    isEntitled() {
      try {
        if (!this.user) {
          Logger.log(`Error: checking isEntitled() in useUserStore when we have no user`)
          return false;
        }

        const featureFlagsStore = useFeatureFlagsStore()
        const { freePassFlag } = storeToRefs(featureFlagsStore)
        if (freePassFlag.value) {
          return true;
        }

        if (this.isFounder) {
          return true;
        }

        const creationTime = this.user.metadata?.creationTime
        if (!creationTime) {
          return true;
        }
        const whenUserCreated = DateTime.fromHTTP(creationTime);

        const now = DateTime.local()

        const freeTrialExpiryDate = whenUserCreated.plus({ days: 7 });
        if (freeTrialExpiryDate > now) {
          return true;
        }


        const subscriptionsStore = useSubscriptionsStore();
        const { loadSubscriptions } = subscriptionsStore;
        const { haveSubscriptionsLoaded, isSubscribing, subscription } = storeToRefs(subscriptionsStore);
        if (haveSubscriptionsLoaded.value) {
          return (subscription.value && subscription.value.status === 'active')
        } else {
          if (!isSubscribing.value) {
            Logger.log(`isEntitled getter sees isSubscribing is false and starts subscribing to subscriptions`)
            loadSubscriptions();
          }
          return true;
        }

      } catch (error) {
        Logger.logError('Error when checking if isEnabled', error)
        throw error;
      }
    },

    isInFreeTrial() {
      const creationTime = this.user.metadata?.creationTime
      if (!creationTime) {
        throw new Error('Error: user has no creation time so unable to check free trial expiry');
      }

      if(!this.freeTrialExpiryDate) return false;

      const now = DateTime.local()
      const expiryDate = this.freeTrialExpiryDate;
      return expiryDate > now;

    },

    freeTrialExpiryDate(): DateTime | undefined {
      const creationTime = this.user.metadata?.creationTime
      if (!creationTime) return undefined;
      const whenUserCreated = DateTime.fromHTTP(creationTime);
      return whenUserCreated.plus({ days: 7 });
    },

    isFounder() {
      const creationTime = this.user.metadata?.creationTime
      if (!creationTime) {
        throw new Error('Error: user has no creation time so unable to check founder status');
      }
      if (creationTime) {
        const whenUserCreated = DateTime.fromHTTP(creationTime);
        const lastFounderOfficialDate = DateTime.utc(2021, 4, 1);
        if (whenUserCreated && whenUserCreated < lastFounderOfficialDate) {
          return true;
        }
      }
      return false;
    },

    isSubscriber() {
      const subStore = useSubscriptionsStore();
      const { haveSubscriptionsLoaded: subscriptionsLoaded, isSubscribing } = storeToRefs(subStore)

      return !subscriptionsLoaded.value || isSubscribing.value
    },

    tasksLimit() {
      if (this.isFounder || this.isSubscriber) return Constants.MAX_TASKS_WHEN_ACTIVE;
      return Constants.MAX_TASKS_IN_TRIAL;

    },
  },


  actions: {
    setUser(newValue: User) {
      this._user = newValue;
    },

    loadUserProfile() {
      this.name = this._user?.displayName;
      this.photoURL = this.user?.photoURL;
      this.hasProfileLoaded = true;
    },
    async saveUserProfile(name: string, photoURL?: string) {
      try {
        if (!this.user.uid) {
          Logger.logError("User must be logged in, to save her profile");
          return;
        }

        this.photoURL = photoURL ?? this.photoURL;
        this.name = name;

        await this.user.updateProfile({
          displayName: name,
          photoURL: photoURL,
        });
      } catch (error) {
        Logger.logError('Error saving user profile', error)
        throw error
      }
    },

    signUpStep1Done(username: string) {
      this.signUpUser = username;
    },
    signUpStep2Done(password: string) {
      this.signUpPassword = password;
    },

    async signUpStep3Done(
      confirmPassword: string
    ) {
      if (confirmPassword != this.signUpPassword) {
        throw Error(`Your passwords do not match`);
      }
      return await this.register(
          this.signUpUser,
          this.signUpPassword
      );
    },

    async register(username: string, password: string) {
      const credential: UserCredential = await createUserWithEmailAndPassword(auth, username, password);
      this.user = credential.user;
      this.loadUserProfile();
      return this.user;
    },
    async savePhoto(photo: File) {

      try {
        const storage = getStorage();

        const oldUrl = this.user.photoURL;
        if (oldUrl) {
          const oldRef = ref(storage, oldUrl);
          if (oldRef) {
            await deleteObject(oldRef);
          }
        }

        const path = `${this.user.uid}/profilePicture/${photo.name}`;

        const storageRef = ref(storage, path);

        await uploadBytes(storageRef, photo);
        const url = await getDownloadURL(storageRef);
        this.photoURL = url;

        await this.user.updateProfile({
          photoURL: url,
        });

      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            section: "savePhoto",
          },
        });
      }
    },

    unloadUserData() {
      useTasksStore().unsubscribeTasks();
      useTasksStore().clearTasks();

      useNotesStore().unsubscribeNotes();
      useNotesStore().clearNotes();

      useFoldersStore().unsubscribeFolders();
      useFoldersStore().clearFolders();

      useGoalsStore().unsubscribeGoals();
      useGoalsStore().clearGoals();

      useContextsStore().unsubscribeContexts();
      useContextsStore().clearContexts();

      useSettingsStore().clearSettings();

      useSubscriptionsStore().unsubscribeSubscriptions();
      useSubscriptionsStore().clearSubscriptions();
    },

    async loadUserData() {
      try {

        useTasksStore().loadTasks();
        useNotesStore().loadNotes();
        useFoldersStore().loadFolders();
        useGoalsStore().loadGoals();
        useContextsStore().loadContexts();
        useSubscriptionsStore().subscribeToFirestoreSubscriptions();

      } catch (error) {
        Logger.logError('Error loading user profile', error)
        throw error;

      }
    },

    async refreshUserData() {
      try {
        await this.loadUserData();
      } catch (error: any) {
        Logger.logError('DError refreshing user data', error)
        throw error;
      }
    },

    async login(username: string, password: string) {
      try {
        const credential = await signInWithEmailAndPassword(auth, username, password);
        this.loadUserProfile();
        return credential.user;
      } catch (error) {
        Logger.logError('Error in login', error)
        throw error;
      }
    },

    async ifttt_login(username: string, password: string) {
      try {
        const userStore = useUserStore(); // get another instance so we can call other actions
        userStore.unloadUserData();

        const credentials = await signInWithEmailAndPassword(auth,
          username,
          password
        );
        const user = credentials.user;

        return await user?.getIdToken();

      } catch (error) {
        Logger.logError('Error logging into ifttt', error)
        throw error;
      }
    },
    async logout() {
      const result = await signOut();
      if (result.isOk()) {
        const userStore = useUserStore();

        userStore.unloadUserData();

        if (userStore.authChangeUnsubscribe) {
          userStore.authChangeUnsubscribe();
          userStore.authChangeUnsubscribe = null;
        }
      }
      return result;
    },

    async deleteUser() {
      const notifications = useNotificationsStore();
      try {
        if (!this.user) {
          Logger.logError("deleteUser has no user");
          return
        }
        await this.user.delete();
        await auth.signOut();
        this.uid = null;
        this.user = null;
        this.name = "";
        this.email = "";
        this.photoURL = "";
      } catch (error: any) {
        const message = "TaskAngel needs you to approve de-registration by logging in again. Please log in and return to Settings page to try again";
        Logger.logError(message, error)
        notifications.addNotification(new MyNotification("De-register", message))
      }
    },

  },
});
